<template>
    <div class="menu-page mt-9">
        <h1>Solicitações</h1>

        <div class="mt-6">
            <v-card>
                <v-card-title class="table-header">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                    <v-spacer></v-spacer>
                </v-card-title>

                <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                    'items-per-page-text': 'Linhas por página',
                    'items-per-page-all-text': 'Tudo',
                    'items-per-page-options': [5, 10, 30, 50, 100, -1],
                }">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString() }}
                    </template>

                    <template v-slot:[`item.description`]="{ item }">
                        {{ item.description.length > 60 ? item.description.substring(0, 59) + '...' : item.description }}
                    </template>

                    <template v-slot:[`item.result`]="{ item }">
                        {{ item.result && item.result.length > 60 ? item.result.substring(0, 59) + '...' : item.result }}
                    </template>

                    <template v-slot:[`item.user`]="{ item }">
                        {{ item.user.email }}
                    </template>

                    <template v-slot:[`item.files`]="{ item }">
                        <v-icon v-for="(file, index) in item.files" :key="index" @click="open(file)">mdi-file</v-icon>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="actions-container">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon medium class="mr-4" color="primary" @click.stop="object = item; dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon medium class="mr-4" color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                </template>
                                <span>Apagar</span>
                            </v-tooltip>
                        </div>
                    </template>

                </v-data-table>
            </v-card>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="800">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>Solicitar Análise</span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-subtitle><b>{{ object.type }}</b></v-card-subtitle>


                <v-card-text class="mb-4">
                    <v-row>

                        <v-col cols="12">
                            <v-textarea rows="6" v-model="object.description" outlined label="Descrição da solicitação"></v-textarea>
                        </v-col>


                        <v-row class="mt-2 mb-2">
                            <div class="pointer" v-for="(item, index) in object.files" :key="index" @click="open(item)">
                                <v-icon large>mdi-file</v-icon>
                                {{ item.originalName }}
                            </div>
                        </v-row>

                    </v-row>
                </v-card-text>


                <v-divider class="mb-6"></v-divider>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea rows="6" v-model="object.result" outlined label="Resultado da análise" placeholder="Insira aqui o resultado da análise, um parecer ou um link de acesso para um documento completo."></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-select outlined v-model="object.status" hide-details="auto" :items="statuses" label="Status da análise"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="success" @click.native="createOrUpdateObject" :loading="loading">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar solicitação
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar essa solicitação de análise? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';

//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "SolicitacoesView",

    data() {
        return {
            search: "",
            objects: [],
            object: {},
            dialog: false,
            dialogCreateUpdateObject: false,
            noDataText: 'Sem dados',
            headers: [
                { text: "Criação", value: "created" },
                { text: "Tipo", value: "type" },
                { text: "Descrição", value: "description" },
                { text: "Usuário", value: "user" },
                { text: "Arquivos", value: "files" },
                { text: "Status", value: "status" },
                { text: "Resultado", value: "result" },
                { text: "Ações", value: "actions" },
            ],
            statuses: ['Novo', 'Em análise', 'Finalizado'],
            loading: true,
        };
    },

    components: {},

    created() {
        this.init();
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getAnalysis();

            if (resp && resp.error) {
                this.loading = false;
                return emitToastr("error", resp.message);
            }
            else {
                this.objects = resp.message;
            }
            this.loading = false;
        },

        open(item) {
            window.open(`/uploads/${item.fileName}`, "_blank");
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateAnalysis(this.object);

            if (resp && !resp.error && resp.message) {
                emitToastr("success", "Análise editada com sucesso.")
                this.object = { type: '', files: [] };
                this.dialogCreateUpdateObject = false;
            }

            if (resp && resp.error) {
                emitToastr("error", resp.message)
            }

            this.loading = false;
            this.init();
        }
    },
};
</script>
  
<style scoped>
.actions-container {
    display: flex;
}
</style>
  