import Vue from "vue";
import VueRouter from "vue-router";
import Analises from "../views/Analises.vue";
import Calendario from "../views/Calendario.vue";
import Clima from "../views/Clima.vue";
import Conta from "../views/Conta.vue";
import Dashboard from "../views/Dashboard.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Talhao from "../views/NovoTalhao.vue";
import Relatorios from "../views/Relatorios.vue";
import Solicitacoes from "../views/Solicitacoes.vue";
import Talhoes from "../views/Talhoes.vue";
import Usuarios from "../views/Usuarios.vue";

import Project from "../views/Project.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/cases/:id",
    name: "Project",
    component: Project,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/talhoes",
    name: "Talhoes",
    component: Talhoes,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/novo-talhao",
    name: "NovoTalhao",
    component: Talhao,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/clima",
    name: "Clima",
    component: Clima,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/calendario",
    name: "Calendario",
    component: Calendario,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: Relatorios,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/analises",
    name: "Analises",
    component: Analises,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/solicitacoes",
    name: "Solicitacoes",
    component: Solicitacoes,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/conta",
    name: "Conta",
    component: Conta,
    meta: {
      layout: "menu",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/", "/login", "/password-recovery"];
  const authRequired =
    !publicPages.includes(to.path) && !to.path.includes("/cases");
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
