<template>
  <div class="menu-page mt-9">
    <h1>Meus Talhões</h1>

    <div class="controls-container mt-4"></div>
    <div class="map-container mt-6">
      <v-row>
        <v-col cols="12" lg="9" md="8" sm="12">
          <GmapMap :center="center" :zoom="15" map-type-id="hybrid" :options="gMapOptions" style="width: 100%; height: 85vh" ref="map">
            <gmap-polygon :paths="paths"> </gmap-polygon>
            <gmap-info-window v-if="selectedObject && selectedObject.polygon" :options="infoOptions" :position="selectedObject.polygon[0]" :opened="infoOpened" @closeclick="infoOpened = false">
            </gmap-info-window>
          </GmapMap>
        </v-col>

        <v-col cols="12" lg="3" md="4" sm="12">
          <v-card>
            <v-card-title>Meus talhões</v-card-title>
            <v-list>
              <v-list-item-group v-model="model" mandatory color="success">
                <v-list-item v-for="(item, i) in objects" :key="i" @click.native="selecionaTalhao(item)">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}
                      <small>({{ item.agromonitoring_area.toFixed(2) }} ha)</small></v-list-item-title>
                    <v-list-item-subtitle v-text="item.property_name"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="item.culture"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon medium color="error" @click.stop="
                      selectedObjectToDelete = item;
                    dialog = true;
                    ">
                      mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5"> Apagar talhao </v-card-title>
        <v-card-text>Tem certeza que deseja apagar <b>{{ selectedObjectToDelete.name }}</b>? Essa operação é irreversível.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="error" text @click="
            dialog = false;
          selectedObjectToDelete.filed = true;
          createOrUpdateObject();
          ">
            Apagar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Api from "@/lib/Api";

import { emitToastr } from "@/lib/Utils";

export default {
  name: "TalhoesView",

  data() {
    return {
      gMapOptions: {
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      paths: [[]],
      center: { lat: -22.62659, lng: -58.80959 },
      objects: [],
      model: 0,
      infoOpened: false,
      selectedObjectToDelete: {},
      selectedObject: { name: "" },
      infoOptions: { content: "" },
      dialog: false,
    };
  },

  components: {},

  async created() {
    const user = await Api.getRemoteUser();
    if (user && !user.admin && user.plan == 'Gratuito') {
      emitToastr("error", "Acesso indisponível no plano gratuito");
      return this.$router.replace('/calendario');
    }

    this.init();
  },

  methods: {
    async init() {
      const resp = await Api.getTalhoesByUser();
      if (resp && !resp.error && resp.message) this.objects = resp.message;

      this.paths = [];
      this.objects.forEach((obj) => {
        if (obj.polygon) this.paths.push(obj.polygon);
      });

      if (this.objects.length > 0) {
        //this.selecionaTalhao(this.objects[0]);
        this.centerMap();
      }
    },

    async createOrUpdateObject() {
      this.buttonLoading = true;
      const resp = await Api.createOrUpdateTalhao(this.selectedObjectToDelete);

      if (resp && resp.message) {
        if (resp.error) {
          this.buttonLoading = false;
          return emitToastr("error", resp.message);
        } else {
          emitToastr("success", "Operação bem sucedida.");
        }
      }
      await this.init();
      this.buttonLoading = false;
    },

    selecionaTalhao(item) {
      this.selectedObject = item;
      this.infoOptions.content = `${item.name} (${item.agromonitoring_area} ha)`;
      this.infoOpened = true;

      var bounds = new google.maps.LatLngBounds();

      for (var i = 0; i < item.polygon.length; i++) {
        var point = new google.maps.LatLng(
          item.polygon[i].lat,
          item.polygon[i].lng
        );
        bounds.extend(point);
      }

      this.$refs.map.fitBounds(bounds);
    },

    centerMap() {
      if (this.paths.length > 0) {
        var bounds = new google.maps.LatLngBounds();

        this.paths.forEach((path) => {
          path.forEach((coord) => {
            var point = new google.maps.LatLng(coord.lat, coord.lng);
            bounds.extend(point);
          });
        });

        this.$refs.map.fitBounds(bounds);
      }
    },
  },
};
</script>

<style scoped></style>
