<template>
    <div class="menu-page mt-9">
        <h1>Análises</h1>

        <div class="mt-6">
            <v-row align="stretch" class="ma-2">
                <v-col cols="12" lg="4" md="4" sm="6">
                    <v-card class="service-card pa-1 fill-height d-flex flex-column">
                        <v-card-title class="dont-break">Detecção de Doenças e Pestes</v-card-title>
                        <v-card-subtitle>Talvez uma descrição pequena do serviço aqui</v-card-subtitle>
                        <!--
                        <v-card-text>
                            <v-img contain src="@/assets/icon_peste.png" height="50"></v-img>
                        </v-card-text>
                        -->
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn block color="success" @click.native="object.type = 'Detecção de Doenças e Pestes'; dialogCreateUpdateObject = true">Solicitar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="6">
                    <v-card class="service-card pa-1 fill-height d-flex flex-column">
                        <v-card-title class="dont-break">Mapeamento com Drone</v-card-title>
                        <v-card-subtitle>Talvez uma descrição pequena do serviço aqui</v-card-subtitle>
                        <!--
                        <v-card-text>
                            <v-img contain src="@/assets/icon_drone.png" height="50"></v-img>
                        </v-card-text>
                        -->
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn block color="success" @click.native="object.type = 'Mapeamento com Drone'; dialogCreateUpdateObject = true">Solicitar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="6">
                    <v-card class="service-card pa-1 fill-height d-flex flex-column" >
                        <v-card-title class="dont-break">NDVI</v-card-title>
                        <v-card-subtitle>Talvez uma descrição pequena do serviço aqui</v-card-subtitle>
                        <!--
                        <v-card-text>
                            <v-img contain src="@/assets/icon_ndvi.png" height="50"></v-img>
                        </v-card-text>
                        -->
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn block color="success" @click.native="object.type = 'NDVI'; dialogCreateUpdateObject = true">Solicitar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="6">
                    <v-card class="service-card pa-1 fill-height d-flex flex-column">
                        <v-card-title class="dont-break">Mapa Declividade</v-card-title>
                        <v-card-subtitle>Talvez uma descrição pequena do serviço aqui</v-card-subtitle>
                        <!--
                        <v-card-text>
                            <v-img contain src="@/assets/icon_declividade.png" height="50"></v-img>
                        </v-card-text>
                        -->
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn block color="success" @click.native="object.type = 'Mapa Declividade'; dialogCreateUpdateObject = true">Solicitar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="6">
                    <v-card class="service-card pa-1 fill-height d-flex flex-column">
                        <v-card-title class="dont-break">Contagem de Arbustos</v-card-title>
                        <v-card-subtitle>Talvez uma descrição pequena do serviço aqui</v-card-subtitle>
                        <!--
                        <v-card-text>
                            <v-img contain src="@/assets/icon_arbustos.png" height="50"></v-img>
                        </v-card-text>
                        -->
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn block color="success" @click.native="object.type = 'Contagem de Arbustos'; dialogCreateUpdateObject = true">Solicitar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="6">
                    <v-card class="service-card pa-1 fill-height d-flex flex-column">
                        <v-card-title class="dont-break">Outros Serviços</v-card-title>
                        <v-card-subtitle>Talvez uma descrição pequena do serviço aqui</v-card-subtitle>
                        <!--
                        <v-card-text>
                            <v-img contain src="@/assets/icon_arbustos.png" height="50"></v-img>
                        </v-card-text>
                        -->
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn block color="success" @click.native="object.type = 'Outros Serviços'; dialogCreateUpdateObject = true">Solicitar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="800">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>Solicitar Análise</span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-subtitle><b>{{ object.type }}</b></v-card-subtitle>


                <v-card-text>
                    <v-row>

                        <v-col cols="12">
                            <v-textarea rows="6" v-model="object.description" outlined label="Por favor, descreva sua solicitação"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-file-input v-model="file" @change="uploadFile" label="Arquivos" outlined dense :messages="fileInputMessage" :loading="buttonLoading" hide-details="auto"></v-file-input>
                        </v-col>

                        <v-row class="mt-4">
                            <div class="ma-4" v-for="(item, index) in object.files" :key="index">
                                <span>
                                    <v-badge color="red" class="ml-8 icon-splice" icon="mdi-close-thick" @click.native="object.files.splice(index, 1)"></v-badge>
                                </span>
                                <v-icon large>mdi-file</v-icon>
                                {{ item.originalName }}
                            </div>
                        </v-row>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="success" @click.native="createOrUpdateObject" :loading="buttonLoading">Solicitar análise</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "AnalisesView",

    data() {
        return {
            object: { type: '', files: [] },
            dialogCreateUpdateObject: false,
            file: null,
            buttonLoading: false,
            fileInputMessage: "Insira qualquer tipo de arquivo, quantos quiser, com limite de até 10mb cada."

        };
    },

    components: {},

    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && !user.admin && user.plan == 'Gratuito') {
            emitToastr("error", "Acesso indisponível no plano gratuito");
            return this.$router.replace('/calendario');
        }
    },

    methods: {

        async uploadFile(data) {
            this.buttonLoading = true;
            const resp = await Api.uploadFile(data);

            if (resp && !resp.error && resp.message) {
                this.object.files.push(resp.message);
            }

            this.buttonLoading = false;

            if (resp && resp.error) {
                emitToastr("error", resp.message)
            }
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateAnalysis(this.object);

            if (resp && !resp.error && resp.message) {
                emitToastr("success", "Análise solicitada com sucesso. Por favor aguarde o retorno.")
                this.object = { type: '', files: [] };
                this.dialogCreateUpdateObject = false;
            }

            if (resp && resp.error) {
                emitToastr("error", resp.message)
            }

            this.buttonLoading = false;
        }
    },
};
</script>
  
<style scoped>
.icon-splice {
    cursor: pointer;
    transform: translate(-15px, -20px);
}

.dont-break {
    white-space: pre-wrap;
    word-break: break-word;
}

</style>
  