<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import HeroButton from "@/components/HeroButton"
import BenifitCard from "@/components/BenifitCard"
import SectionContact from "@/components/sections/SectionContact"

import SolutionCard from "@/components/SolutionCard.vue";
import PricingCard from "@/components/PricingCard.vue"
export default {
    name: "HomeView",

    data: () => ({
        pricingSelected: null,
        hectares: ["Até 10 hectares", "Entre 10 e 25 hectares", "Entre 25 e 50 hectares", "Entre 50 e 100 hectares", "Mais que 100 hectares"],
        loading: false,
        planos: [
            { id: "Gratuito", name: "Gratuito" },
            { id: "Plus", name: "Plus" }
        ],
        form: {
            nome: "",
            email: "",
            telefone: "",
            estado: "",
            hectares: "",
            plano: "",
            mensagem: ""
        }
    }),

    components: { HeroButton, BenifitCard, PricingCard, SolutionCard, SectionContact },

    computed: {
        pricingModel: {
            get() {
                return this.pricingSelected != null;
            },
            set(value) {
                if (!value) {
                    this.pricingSelected = null
                }
            }
        }
    },

    async mounted() {
        this.$root.$refs.solutionsSection = this.$refs.solutions;
        this.$root.$refs.pricingSection = this.$refs.pricing;
        this.$root.$refs.aboutSection = this.$refs.about;
        this.$root.$refs.contactSection = this.$refs.contact;

        /*
        const resp = await Api.getPlans();
        if (resp && resp.message) {
            this.planos = resp.message;
        }
        */
    },

    methods: {
        selectPricing(pricing) {
            this.pricingSelected = pricing;
        },

        async sendContactMessage() {
            this.loading = true;
            const resp = await Api.sendGenericMessage(this.form);
            this.loading = false;
            this.pricingModel = false;
            if (!resp.error) {
                emitToastr("success", "Mensagem enviada com sucesso. Em breve você receberá um contato.");
            }
        }
    },
};
</script>
  

<template>
    <div class="menu-page" style="padding: 0px !important">
        <AppHeader />
        <section id="hero" class="section-app align-center">
            <div class="section-app-content">
                <h2 class="text-3xl black--text mb-4 font-weight-black">
                    Seus <span class="primary--text">dados</span> são o nosso negócio.<br />
                    Sua <span class="primary--text">produtividade</span> nossa prioridade.
                </h2>
                <p class="gray--text text-lg mb-0">Insights precisos, reais e acionáveis para sua lavoura</p>
            </div>
        </section>
        <section id="hero-image" class="section-app">
            <div class="section-app-content absolute h-full d-flex justify-end">
                <HeroButton class="mr-8 mb-sm-16">Saiba mais</HeroButton>
            </div>
            <img src="@/assets/hero.jpg" class="w-full h-full cover" alt="" />
        </section>
        <section id="connect-and-envolve" class="section-app">
            <div class="section-app-content d-flex flex-column align-center">
                <div class="d-flex flex-column align-center" style="max-width:40rem;">
                    <img src="@/assets/leafmap.svg" alt="leafmap" class="mb-11" style="width: 6.75rem;" />
                    <h2 class="font-weight-black text-3xl text-center mb-10 black--text">Conecte-se e evolua conosco</h2>
                    <p class="gray--text text-lg text-center mb-10">
                        <b class="font-weight-bold text-center">LeafMap</b>, a plataforma de agricultura de precisão da
                        Terraform Brasil que utiliza tecnologia de ponta
                        e o
                        conhecimento que você tem de seus talhões para maximizar os resultados de sua lavoura.
                    </p>
                    <AppButton>Comece agora</AppButton>
                </div>
            </div>
        </section>
        <section id="benifits" class="light-blue section-app">
            <div class="section-app-content section-grid">
                <div class="d-flex flex-column justify-center">
                    <h2 class="text-3xl black--text font-weight-black mb-7">Vantagens</h2>
                    <p class="gray--text text-lg text-justify mb-0">
                        Monitore a saúde da sua lavoura e obtenha a vantagem competitiva que o seu negócio precisa, aliando
                        tecnologia de ponta, eficiência na execução e excelência na gestão.
                    </p>
                </div>
                <div id="benifits-grid">
                    <ul class="pa-0">
                        <li>
                            <BenifitCard img="agilidade.svg" title="Agilidade" description="Respostas rápidas a fatores de stress em sua fazenda. Conexão remota direta com agrônomos." />
                        </li>
                        <li>
                            <BenifitCard img="especialidade.svg" title="Especializada na cafeicultura" description="Fornecemos ferramentas e recursos que atendem às necessidades específicas da cultura." />
                        </li>
                        <li>
                            <BenifitCard img="nuvem.svg" title="Tudo na nuvem" description=" Online. Não é necessário instalar nenhum programa em seu computador." />
                        </li>
                    </ul>
                    <ul class="pa-0">
                        <li>
                            <BenifitCard img="economia.svg" title="Economia" description="Economize água, fertilizantes e defensivos agrícolas mapeando as necessidades específicas de
                                seus talhões." />
                        </li>
                        <li>
                            <BenifitCard img="planejamento.svg" title="Planejamento e organização" description="Tenha em mãos um calendário específico para o gerenciamento de atividades em sua lavoura." />
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section id="infra" class="white section-app">
            <div class="section-app-content section-grid">
                <div class="relative d-flex flex-column">
                    <h3 class="text-xl black--text font-weight-black" style="max-width: 27rem;">
                        Use dados para entender e otimizar a performance de cada talhão.
                    </h3>
                </div>
                <div class="">
                    <p class="text-lg gray--text mb-sm-10 mb-4">
                        O panorama agrícola do mundo hoje está mudando rapidamente. À medida que a demanda dos agricultores
                        por produtividade e otimização do campo aumentam, informações precisas são necessárias para agilizar
                        a tomada de decisão.
                    </p>
                    <div class="d-flex">
                        <AppButton class="mr-2 mr-sm-7" @click="$root.$refs.pricingSection.scrollIntoView({ block: 'start', behavior: 'smooth' });">Preços</AppButton>
                        <AppButton variant="outlined" @click="$root.$refs.contactSection.scrollIntoView({ block: 'start', behavior: 'smooth' });">Contato</AppButton>
                    </div>
                </div>
            </div>
        </section>
        <img src="@/assets/infra.png" id="float-infra" class="align-self-end" />
        <section id="solutions" ref="solutions" class="white section-app">
            <div class="section-app-content d-flex flex-column justify-center align-center">
                <h2 class="text-2xl mb-8 mb-sm-15 font-weight-black black--text">Nossas Soluções</h2>
                <div class="section-grid">
                    <SolutionCard title="Dados Climáticos" :details="['Obtenha diariamente indicadores climáticos (temperatura, precipitação, vento e radiação solar)', 'Condições atuais, previsões estendidas', 'Planeje as operações de campo com maior segurança']" />
                    <SolutionCard title="Central de Monitoramento" :details="['Crie de zonas de manejo', 'Monitore o desenvolvimento de seus talhões com imagens de satélite']" />
                    <SolutionCard title="Calendário de Atividades" :details="['Planeje e registre as atividades no calendário de manejo inteligente']" />
                    <SolutionCard title="Machine Learning" :details="['Diagnóstico de pestes e doenças em folhas de café', 'Contagem de arbustos']"  link="cases/contagem"/>
                    <SolutionCard title="Análises e relatórios" :details="['Mapa ortomosaico e VARI (drone)', 'Análise NDVI, VARI, CSWI e GNVI. Tenha conhecimento de da produtividade, vigor e stress em seus talhões.', 'Faça o download do mapas e análises solicitados pela plataforma']" link="cases/diagnostico"/>
                    <SolutionCard title="Mapeamento digital completo" :details="['Serviço completo de voo de drone, planejado com 7 dias úteis de antecedência para região do ES', 'Processamento de imagens e geração de mapas e análises em até 72 horas após o voo']" link="cases/mapas"/>
                </div>
            </div>
        </section>
        <section id="about-us" ref="about" class="white section-app">
            <div class="section-app-content">
                <h2 class="black--text font-weight-black text-2xl mb-4">Sobre nós</h2>
                <div class="primary" style="width: 6rem; height: 0.25rem"></div>
            </div>
        </section>
        <div id="about-image" class="w-full">
            <img src="@/assets/about.jpg" class="w-full h-full cover" />
        </div>
        <section id="about-us-details" class="white section-app">
            <div class="section-app-content section-grid">
                <div class="gray--text text-lg">
                    Plantamos a semente em 2022 e a nutrimos desde então. Fomos selecionados pelo programa de fomento a
                    Startups Centelha e vimos nossa ideia brotar com os incentivos do governo do estado do Espírito Santo.
                    Em 2023, nossa plataforma de software está finalmente entrando no mercado e colhendo seus frutos.
                </div>
                <div class="gray--text text-lg">
                    A missão da Terraform Brasil é auxiliar indivíduos envolvidos na indústria agrícola a adquirir dados
                    altamente precisos para embasar suas escolhas de forma eficiente, aprimorando o desempenho de suas
                    tarefas cotidianas e simplificando suas operações.
                </div>
            </div>
        </section>
        <section id="about-us-banner" class="primary section-app">
            <div class="section-app-content d-flex flex-column align-center justify-center">
                <h2 class="secondary-dark--text text-3xl font-weight-black mb-7 text-center">Missão</h2>
                <p class="bright-blue--text text-xl text-center mb-0">
                    Auxiliar produtores e empresas agrícolas a performar
                    com excelência com dados de campo altamente precisos
                </p>
            </div>
        </section>
        <section id="pricing" ref="pricing" class="white section-app">
            <div class="section-app-content d-flex flex-column align-center">
                <h2 class="mb-8 font-weight-black text-2xl">Preços</h2>
                <div class="horizontal-scroll">
                    <ul id="pricing-grid" class="pa-0">
                        <li>
                            <PricingCard title="Gratuito" annual_price="" month_price="" @click="selectPricing('64a2b7b1acfa16868cfff258')" :features="['Para pequenas propriedades', 'Calendário de atividades']" />
                        </li>
                        <li>
                            <PricingCard title="Plus"  month_price="198" @click="selectPricing('64a2b7d4acfa16868cfff259')" :features="['Calendário de Atividades', 'Gestão de talhões', 'Histórico de NDVI', 'Imagens de satélite', 'Painel de clima', 'Análises']" />
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <div ref="contact" style="height: 10px; top: -50px; pointer-events: none;"></div>
        <SectionContact />
        <AppFooter />
        <v-dialog v-model="pricingModel" max-width="43.75rem">
            <form id="form-contact" class="white w-full pb-4 pt-6 pb-sm-10 pt-sm-16 d-flex flex-column">
                <h2 class="text-xl font-weight-black black--text mb-2 mb-sm-8">
                    Preencha o formulário e entraremos em contato!
                </h2>
                <div class="grid mb-7">
                    <v-text-field v-model="form.nome" class="app-text-field mb-1 mb-sm-3 w-full" label="Nome Completo*" hide-details="auto"></v-text-field>
                    <v-text-field v-model="form.email" class="app-text-field mb-1 mb-sm-3 w-full" label="Email*" hide-details="auto"></v-text-field>
                    <v-text-field v-model="form.telefone" v-mask="'(##) #########'" class="app-text-field mb-1 mb-sm-3 w-full" label="Telefone (de preferência whatsapp)*" hide-details="auto"></v-text-field>
                    <v-select v-model="form.estado" class="app-select mb-1 mb-sm-3 w-full" hide-details="auto" :items="$root.$refs.global.estados" label="Qual seu estado?"></v-select>
                    <v-select v-model="form.hectares" class="app-select mb-1 mb-sm-3 w-full" hide-details="auto" :items="hectares" label="Com quantos hectares você trabalha?*"></v-select>
                    <v-select v-model="form.plano" class="app-select mb-1 mb-sm-3 w-full" hide-details="auto" item-value="id" item-text="name" :items="planos" label="Você tem interesse em qual plano?*"></v-select>
                    <v-text-field v-model="form.mensagem" class="app-select mb-2 mb-sm-7 w-full" hide-details="auto" label="Mensagem" placeholder="Deixe aqui sua mensagem"></v-text-field>
                </div>
                <p class="font-weight-semibold text-justify text-sm gray--text mb-2 mb-sm-4">
                    Ao informar meus dados, eu concordo com a política de privacidade da Terraform Brasil. Você pode alterar
                    suas permissões de comunicação a qualquer tempo.</p>
                <AppButton @click="sendContactMessage()" :loading="loading" :disabled="loading">Enviar</AppButton>
            </form>
        </v-dialog>
    </div>
</template>

<style scoped>
.horizontal-scroll {
    max-width: 100%;
    overflow-x: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

#hero {
    padding-top: 10.375rem;
    padding-bottom: 4.375rem;
}

#hero-image {
    aspect-ratio: 5/3;
    position: relative;
}

@media screen and (min-width: 600px) {
    #hero-image {
        aspect-ratio: 1920/590;
    }
}

#connect-and-envolve {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

#benifits {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@media screen and (min-width: 600px) {
    #benifits-grid {
        display: grid;
        grid-template-columns: 6fr 6fr;
        column-gap: 1rem;
    }
}


@media screen and (min-width: 1264px) {
    #benifits-grid {
        column-gap: 2rem;
    }
}

#benifits-grid>ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    list-style: none;
}

#infra {
    padding-top: 7.5rem;
}


.section-grid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.section-grid> :first-child {
    margin-bottom: 4rem;
}

@media screen and (min-width: 960px) {
    .section-grid {
        display: grid;
        grid-template-columns: 6fr 6fr;
        column-gap: 3rem;
    }

    .section-grid> :first-child {
        margin-bottom: unset;
    }
}


@media screen and (min-width: 1264px) {
    .section-grid {
        grid-template-columns: 33.75rem 33.75rem;
        column-gap: 5.75rem;
    }
}


#float-infra {
    width: 90%;
    margin-left: 10%;
    margin-top: 4rem;
    margin-bottom: 6rem;
}

@media screen and (min-width: 600px) {
    #float-infra {
        width: 60%;
        margin-left: 40%;
        margin-top: -4rem;
        margin-bottom: 6rem;
    }

}

@media screen and (min-width: 960px) {
    #float-infra {
        margin-top: 0rem;
        width: 44.5vw;
        margin-top: -2.875rem;
        margin-left: 4.6875vw;
        margin-bottom: 6rem;
    }
}

#solutions {
    padding-bottom: 6rem;
}

@media screen and (min-width: 960px) {
    #solutions>.section-app-content>.section-grid {
        grid-template-columns: 4fr 4fr 4fr;
        column-gap: 1.25rem;
        row-gap: 2rem;
        margin-bottom: 0px;
    }
}


@media screen and (min-width: 1264px) {
    #solutions>.section-app-content>.section-grid {
        column-gap: 1.875rem;
        row-gap: 2.875rem;
    }
}

#about-us {
    padding-bottom: 3.125rem;
}

#about-us-details {
    padding-bottom: 6rem;
    padding-top: 6rem;
}

#about-us-banner {
    padding-bottom: 6rem;
    padding-top: 6rem;
}

#pricing {
    padding-top: 4.125rem;
    padding-bottom: 6.875rem;
}

#pricing-grid {
    display: grid;
    grid-template-columns: 80vw 80vw;
    column-gap: 1.75rem;
}

@media screen and (min-width: 600px) {
    #pricing-grid {
        grid-template-columns: 16rem 16rem;
        column-gap: 1.75rem;
    }
}


ul#pricing-grid {
    list-style: none;
}

#form-contact {
    padding-left: 2rem;
    padding-right: 2rem;
}

#form-contact .grid {
    display: flex;
    flex-direction: column;

}

@media screen and (min-width: 600px) {
    #form-contact {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
    }

    #form-contact .grid {
        display: grid;
        grid-template-columns: auto;
        column-gap: 2rem;
        row-gap: 1.5rem;
        grid-template-columns: auto auto;
    }
}


#about-image {
    aspect-ratio: 5/3;
}

@media screen and (min-width: 600px) {
    #about-image {
        aspect-ratio: 1920/592;
    }
}

#form-contact .grid>div:last-child {
    grid-column: 1 / span 2;
}
</style>
  