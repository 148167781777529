var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-doc my-6"},[_c('button',{staticClass:"d-flex black--text pa-9 justify-space-between w-full",on:{"click":_vm.toggle}},[_c('h3',{staticClass:"text-lg-2 font-weight-bold",class:{
            'black--text': !_vm.opened,
            'primary--text': _vm.opened
        }},[_vm._v(_vm._s(_vm.article.title))]),(_vm.opened)?_c('IconMinus',{class:{
            'black--text': !_vm.opened,
            'primary--text': _vm.opened
        }}):_c('IconPlus',{class:{
            'black--text': !_vm.opened,
            'primary--text': _vm.opened
        }})],1),(_vm.opened)?_c('div',{staticClass:"px-9 pt-4 pb-9 d-flex flex-column align-stretch"},[(_vm.article.blocks)?_c('ul',{staticClass:"pa-0 pr-sm-16",staticStyle:{"list-style":"none"}},_vm._l((_vm.article.blocks),function(block,i){return _c('li',{key:i},[(block.type == 'text')?_c('p',{staticClass:"mb-4 text-lg",class:{ 'black--text pt-4': block.bold, 'gray--text': !block.bold }},[_vm._v(" "+_vm._s(block.content)+" ")]):_vm._e(),(block.type == 'image')?_c('div',{staticClass:"d-flex justify-center py-8"},[_c('img',{attrs:{"src":require('@/assets/' + block.src)}})]):_vm._e(),(block.type == 'formula')?_c('div',{staticClass:"d-flex align-start mb-4"},[(block.label)?_c('p',{staticClass:"gray--text text-lg mr-8"},[_vm._v(_vm._s(block.label))]):_vm._e(),_c('img',{attrs:{"src":require('@/assets/' + block.src)}})]):_vm._e()])}),0):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }