<script>
export default {
    props: {
        suggested: { type: Boolean, required: false },
        title: { type: String, required: true },
        annual_price: { type: String, required: false },
        month_price: { type: String, required: true },
        features: { type: Array, required: true }/*string[]*/
    },
    data: () => ({
        annual: false
    }),
    methods: {
        setAnnual(value) {
            this.annual = value
        },
        onClick() {
            this.$emit('click')
        }
    },
    computed: {
        pricetag() {
            const val = this.annual ? this.annual_price : this.month_price
            if (Number.isNaN(parseFloat(val))) {
                return val
            } else {
                return "R$" + val;
            }
        }
    }
}
</script>
<template>
    <div class="pricing-card w-full h-full px-5 py-9 app-shadow app-radius" :class="{ 'secondary-dark': suggested, 'white': !suggested }">
        <h3 class="mb-4 font-weight-bold" :class="{ 'black--text': !suggested, 'white--text': suggested }">{{ title }}</h3>
        <div class="d-flex align-center justify-space-between">
            <div class="font-weight-black text-lg-3 text-height" :class="{ 'black--text': !suggested, 'white--text': suggested }">
                {{ pricetag || " " }}
            </div>
            <div class="d-flex align-center" v-if="annual_price != month_price">
                <button class="pricing-button app-radius px-2 py-1 mr-2 text-base" :class="{ selected: !annual, 'dark-mode': suggested }" @click="setAnnual(false)">Mês</button>
                <!--
                <button class="pricing-button app-radius px-2 py-1 text-base"
                    :class="{ selected: annual, 'dark-mode': suggested }" @click="setAnnual(true)">Ano</button>
                    -->
            </div>
        </div>
        <AppButton class="w-full my-7" :color="suggested ? 'white' : 'primary'" @click="onClick">
            Escolha este plano
        </AppButton>
        <ul class="pa-0">
            <li class="mb-4 d-flex align-center " v-for="feature in features" :key="feature">
                <img :src="suggested ? require('@/assets/check-white.svg') : require('@/assets/check.svg')" alt="" class="mr-3 check-icon flex-shrink-0" />
                <div class="text-base" :class="{ 'black--text': !suggested, 'white--text': suggested }">
                    {{ feature }}
                </div>
            </li>
        </ul>
    </div>
</template>
<style scoped>
.pricing-button:not(.dark-mode):not(.selected) {
    border: 1px solid #47C178;
    color: #47C178;
    background: white;
}

.pricing-button:not(.dark-mode).selected {
    background: #47C178;
    color: white;
}

.pricing-button.dark-mode:not(.selected) {
    border: 1px solid white;
    color: white;
}

.pricing-button.dark-mode.selected {
    background: white;
    color: #47C178;
}

.check-icon {
    width: 1rem;
}

.text-height {
    height: 40px;
}
</style>