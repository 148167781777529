<template>
  <v-app style="background-color: white" class="text-base black--text">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>
  
    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar" v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" permanent :mini-variant.sync="mini" v-model="drawer" app>
        <div class="menu-back-button">
          <v-btn v-if="!mini" icon color="black" @click.native="mini = true">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>

        <center class="avatar_container mt-4 mb-4">
          <img v-if="!mini" src="@/assets/leafmap_logo2.png" width="200" />
          <img v-if="mini" src="@/assets/menu_logo.png" width="35" />
        </center>

        <v-list dense>
          <router-link to="/dashboard" :class="{ disabled: user.plan == 'Gratuito' && !user.admin }">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-home-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/talhoes" :class="{ disabled: user.plan == 'Gratuito' && !user.admin }">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-vector-polygon</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Talhões</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/novo-talhao" :class="{ disabled: user.plan == 'Gratuito' && !user.admin }">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-shape-polygon-plus</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Novo Talhão</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/clima" :class="{ disabled: user.plan == 'Gratuito' && !user.admin }">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-weather-partly-rainy</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Clima</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/calendario">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-calendar-month-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Calendário</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/analises" :class="{ disabled: user.plan == 'Gratuito' && !user.admin }">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-layers-search-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Solicitar Análise</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/relatorios" :class="{ disabled: user.plan == 'Gratuito' && !user.admin }">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-file-document-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Relatórios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/conta">
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="icon">mdi-account-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-title class="menu-item">Minha Conta</v-list-item-title>
            </v-list-item>
          </router-link>

          <v-list v-if="user && user.admin">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title class="menu-item">Administração</v-list-item-title>
              </template>
              <template v-slot:prependIcon>
                <v-icon class="icon">mdi-cog-outline</v-icon>
              </template>

              <router-link to="/solicitacoes">
                <v-list-item>
                  <v-list-item-icon class="item_icon">
                    <v-icon class="icon">mdi-map-plus</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Solicitações</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/usuarios">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-account-multiple-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Usuários</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-group>
          </v-list>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid class="menu-page-container">
          <router-view fluid />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "App",

  data: () => ({
    drawer: true,
    mini: true,
    showLoading: false,
    projectName: "Leafmap",
    user: {},
    estados: [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahia",
      "Ceará",
      "Distrito Federal",
      "Espírito Santo",
      "Goiás",
      "Maranhão",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins",
    ],
  }),

  methods: {
    logout() {
      Api.logout();
    },

    setLoading(loading) {
      this.showLoading = loading;
    },

    updateUser(user) {
      this.user = user;
    },
  },

  created() {
    this.$root.$refs.global = this;
  },

  async mounted() {
    this.user = Api.getUser();

    if (this.user && this.user._id) {
      const resp = await Api.getRemoteUser();
      this.user = resp;
    }

    this.$forceUpdate();

    this.$vuetify.breakpoint.lgAndUp
      ? (this.drawer = true)
      : (this.drawer = false);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.menu-page-container {}

.toolbar {
  font-weight: 700;
  color: black;
}

.drawer {
  background-color: rgb(255, 255, 255);
  z-index: 100;
}

.v-list-item {
  margin-bottom: 10px;
}

.menu-back-button {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 3px;
  padding-top: 3px;
}

.icon {
  color: rgb(40, 40, 40);
}

.item_icon {
  margin-right: 16px !important;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-item {
  color: rgb(40, 40, 40);
  font-weight: 600 !important;
  font-size: 14px !important;
  text-overflow: none !important;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
