<script>
import { emitToastr } from '@/lib/Utils';
import Api from '../../lib/Api';

export default {
    name: "SectionContact",
    data: () => ({
        hectares: ["Até 10 hectares", "Entre 10 e 25 hectares", "Entre 25 e 50 hectares", "Entre 50 e 100 hectares", "Mais que 100 hectares"],
        planos: [
            { id: "Gratuito", name: "Gratuito" },
            { id: "Plus", name: "Plus" }
        ],
        loading: false,
        form: {
            nome: "",
            email: "",
            telefone: "",
            estado: "",
            hectares: "",
            plano: "",
            mensagem: ""
        }
    }),

    async mounted() {
        /*
        const resp = await Api.getPlans();
        if (resp && resp.message) {
            this.planos = resp.message;
        }
        */
    },

    methods: {
        async sendContactMessage() {
            this.loading = true;
            const resp = await Api.sendGenericMessage(this.form);
            this.loading = false;
            if (!resp.error) {
                emitToastr("success", "Mensagem enviada com sucesso. Em breve você receberá um contato.");
            }
        }
    }

}
</script>
<template>
    <div>
        <section id="contact" class="light-blue section-app">
            <div class="section-app-content d-flex align-center justify-space-between flex-column flex-md-row">
                <div class="flex-grow-1">
                    <h2 class="black--text text-2xl font-weight-black mb-6">Alguma dúvida? <br />Contate-nos agora mesmo.
                    </h2>
                    <p class="gray--text text-base-2 mb-md-16">
                        Desbloqueie agora os recursos pagos da plataforma. Agende uma
                        apresentação com a equipe, e descubra como a solução LeafMap pode te proporcionar eficiência,
                        controle e economia.
                    </p>
                    <div class="gray--text text-base-2">Email</div>
                    <div class="black--text text-base-2 mb-4 font-weight-bold">contato@terraformbrasil.com</div>
                    <div class="gray--text text-base-2">Telefone</div>
                    <div class="black--text text-base-2 mb-4 font-weight-bold">+55 992246472</div>
                    <div class="gray--text text-base-2">Endereço</div>
                    <div class="black--text text-base-2 mb-4 black--text font-weight-bold">Serra, ES</div>
                </div>
                <div class="form white app-shadow flex-shrink-0 d-flex flex-column align-center">
                    <h3 class="mb-4 black--text font-weight-black text-xl">Contato</h3>
                    <v-text-field v-model="form.nome" class="app-text-field mb-3 w-full" label="Nome Completo*" hide-details="auto"></v-text-field>
                    <v-text-field v-model="form.email" class="app-text-field mb-3 w-full" label="Email*" hide-details="auto"></v-text-field>
                    <v-text-field v-model="form.telefone" v-mask="'(##) #########'" class="app-text-field mb-3 w-full" label="Qual o seu telefone? (de preferência whatsapp)*" hide-details="auto"></v-text-field>
                    <v-select v-model="form.estado" class="app-select mb-3 w-full" hide-details="auto" :items="$root.$refs.global.estados" label="Qual seu estado?"></v-select>
                    <v-select v-model="form.hectares" class="app-select mb-3 w-full" hide-details="auto" :items="hectares" label="Com quantos hectares você trabalha?*"></v-select>
                    <v-select v-model="form.plano" class="app-select mb-7 w-full" hide-details="auto" :items="planos" item-value="id" item-text="name" label="Você tem interesse em qual plano?*"></v-select>
                    <v-text-field v-model="form.mensagem" class="app-select mb-2 mb-sm-7 w-full" hide-details="auto" label="Mensagem" placeholder="Deixe aqui sua mensagem"></v-text-field>
                    <p class="gray--text text-justify font-weight-semibold text-sm mb-5">
                        Ao informar meus dados, eu concordo com a política de privacidade da Terraform Brasil. Você pode
                        alterar suas permissões de comunicação a qualquer tempo.
                    </p>
                    <AppButton @click="sendContactMessage()" :loading="loading" :disabled="loading">Enviar</AppButton>
                </div>
            </div>
        </section>
        <section id="discover" class="primary section-app">
            <div class="section-app-content d-flex flex-column align-center">
                <h2 class="white--text text-2xl font-weight-black" style="max-width: 38.75rem;">
                    Descubra o que a <span class="secondary-dark--text"> Terraform Brasil</span> pode fazer por você e seu
                    negócio
                </h2>
            </div>
        </section>
    </div>
</template>
<style>
#contact {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
}

#contact .form {
    min-height: 47.5rem;
    padding: 3rem 2rem;
    width: 100%;
}

@media screen and (min-width: 600px) {
    #contact .form {
        padding: 3rem 2rem;
    }
}

@media screen and (min-width: 960px) {
    #contact .form {
        width: 27.875rem;
        padding: 4rem 3rem;
    }
}

#contact .section-app-content>div:first-child {
    margin-right: 0rem;
}

@media screen and (min-width: 600px) {
    #contact .section-app-content>div:first-child {
        margin-right: 4rem;
    }
}

@media screen and (min-width: 960px) {
    #contact .section-app-content>div:first-child {
        margin-right: 7rem;
    }
}

#discover {
    padding-bottom: 6rem;
    padding-top: 6rem;
}
</style>