<script>
import IconMinus from "../components/icons/IconMinus"
import IconPlus from "../components/icons/IconPlus"
export default {
    data: () => ({
        opened: true
    }),
    props: {
        article: { type: Object, required: true }
    },
    methods: {
        toggle() {
            this.opened = !this.opened
        }
    },
    components: {
        IconMinus, IconPlus
    }
}
</script>
<template>
    <div class="article-doc my-6">
        <button class="d-flex black--text pa-9 justify-space-between w-full" @click="toggle">
            <h3 class="text-lg-2 font-weight-bold" :class="{
                'black--text': !opened,
                'primary--text': opened
            }">{{ article.title }}</h3>
            <IconMinus :class="{
                'black--text': !opened,
                'primary--text': opened
            }" v-if="opened" />
            <IconPlus :class="{
                'black--text': !opened,
                'primary--text': opened
            }" v-else />
        </button>
        <div class="px-9 pt-4 pb-9 d-flex flex-column align-stretch" v-if="opened">
            <ul class="pa-0 pr-sm-16" style="list-style: none;" v-if="article.blocks">
                <li v-for="(block, i) in article.blocks" :key="i">
                    <p v-if="block.type == 'text'" :class="{ 'black--text pt-4': block.bold, 'gray--text': !block.bold }"
                        class="mb-4 text-lg">
                        {{ block.content }}
                    </p>
                    <div class="d-flex justify-center py-8" v-if="block.type == 'image'">
                        <img :src="require('@/assets/' + block.src)" />
                    </div>
                    <div class="d-flex align-start mb-4" v-if="block.type == 'formula'">
                        <p class="gray--text text-lg mr-8" v-if="block.label">{{ block.label }}</p>
                        <img :src="require('@/assets/' + block.src)" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
.article-doc {
    border: 1px solid #9497A1;
}

.article-doc>div>ul>li>div>img {
    max-width: 100%;
}
</style>