<template>
    <div class="menu-page mt-9">
        <h1>Usuários</h1>

        <div class="mt-6">
            <v-col cols="12">
                <v-card>
                    <v-card-title class="table-header">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn class="button-new" color="primary" @click.native="openNew">Novo Usuário</v-btn>
                    </v-card-title>

                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers" :items="objects" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                        'items-per-page-text': 'Linhas por página',
                        'items-per-page-all-text': 'Tudo',
                        'items-per-page-options': [5, 10, 30, 50, 100, -1],
                    }">

                        <template v-slot:[`item.created`]="{ item }">
                            {{ new Date(item.created).toLocaleString() }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="actions-container">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium class="mr-2" color="primary" @click.stop="object = item; dialogCreateUpdateObject = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon medium color="error" @click.stop="object = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                                    </template>
                                    <span>Apagar</span>
                                </v-tooltip>
                            </div>
                        </template>

                    </v-data-table>
                </v-card>

            </v-col>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="900">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.email" label="E-mail" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.name" label="Primeiro nome" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.last_name" label="Sobrenome" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-select autocomplete="nope" dense v-model="object.state" label="Estado" outlined hide-details="auto" :items="$root.$refs.global.estados"></v-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" v-mask="'(##) #########'" dense v-model="object.phone" label="Telefone" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field type="number" autocomplete="nope" dense v-model="object.area" label="Área (em hectares)" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-select autocomplete="nope" dense v-model="object.plan" label="Plano" outlined hide-details="auto" item-value="id" item-text="name" :items="plans"></v-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field autocomplete="nope" dense v-model="object.password" label="Senha inicial" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-switch class="mt-1" v-model="object.admin" inset label="Administrador" hide-details="auto"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar usuário
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o usuário <b>{{ object.email }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; object.filed = true; createOrUpdateObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "UsuariosView",

    data() {
        return {
            objects: [],
            dialogCreateUpdateObject: false,
            dialog: false,
            search: '',
            noDataText: 'Sem dados',
            object: {},
            plans: [
                { id: "Gratuito", name: "Gratuito" },
                { id: "Plus", name: "Plus" }
            ],
            headers: [
                { text: "Nome", value: "name" },
                { text: "E-mail", value: "email" },
                { text: "Telefone", value: "phone" },
                { text: "Estado", value: "state" },
                { text: "Área", value: "area" },
                { text: "Plano", value: "plan" },
                { text: "Criação", value: "created" },
                { text: "Ações", value: "actions" },
            ],
            buttonLoading: false,
            loading: true
        };
    },

    components: {},

    async mounted() {
        this.init();

        /*
        const resp = await Api.getPlans();
        if (resp && resp.message) {
            if (resp.error) {
                return emitToastr('error', resp.message);
            }
            this.plans = resp.message;
        }
        */
    },

    methods: {

        async init() {
            this.loading = true;
            const resp = await Api.getUsers();
            if (resp && resp.message) {
                if (resp.error) {
                    this.loading = false;
                    return emitToastr('error', resp.message);
                }
                this.objects = resp.message;
            }
            this.loading = false;
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;
            const resp = await Api.createOrUpdateUser(this.object);

            if (resp && resp.message) {
                if (resp.error) {
                    this.buttonLoading = false;
                    return emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Operação bem sucedida.");
                }
            }
            await this.init();
            this.buttonLoading = false;
            this.dialogCreateUpdateObject = false;
        },

        openNew() {
            this.object = {};
            this.dialogCreateUpdateObject = true;
        }
    },
};
</script>
  
<style scoped>
.bottom-modal-container {
    padding: 4%;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title-span {
    width: 100px;
}

.actions-container {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .table-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .button-new {
        margin-top: 20px;
    }
}
</style>
  