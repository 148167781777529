import axios from "axios";
import { emitToastr } from "./Utils";

//const API_URL = "http://localhost:3005/admin";
const API_URL = "https://terraformbrasil.com/admin";

class Api {
  login = async (email, password, remember) => {
    const endpoint = "/auth";
    let result = await this.authPost(endpoint, { email, password, remember });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  createUser = async (email, password, name, last_name, phone) => {
    const resp = await this.authPost("/create-user", {
      email,
      password,
      name,
      last_name,
      phone,
    });
    if (resp && resp.new_user && resp.token) {
      window.localStorage.setItem("token", resp.token);
      window.localStorage.setItem("user", JSON.stringify(resp.new_user));
      return true;
    }
    return false;
  };

  getRemoteUser = async () => {
    if (!this.getToken()) return;
    const user = await this.authPost("/get-user", {}, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
  };

  getPlans = async () => {
    const resp = await this.authPost("/get-plans", {}, {});
    return resp;
  };

  sendGenericMessage = async (data) => {
    const resp = await this.authPost("/send-generic-message", data, {});
    return resp;
  };

  getUsers = async () => {
    const resp = await this.authPost("/get-users", {}, {});
    return resp;
  };

  createOrUpdateUser = async (user) => {
    const resp = await this.authPost("/create-or-update-user", { user }, {});
    return resp;
  };

  getTalhoes = async () => {
    const resp = await this.authPost("/get-talhoes", {}, {});
    return resp;
  };

  getTalhoesByUser = async () => {
    const resp = await this.authPost("/get-talhoes-by-user", {}, {});
    return resp;
  };

  createOrUpdateTalhao = async (talhao) => {
    const resp = await this.authPost(
      "/create-or-update-talhao",
      { talhao },
      {}
    );
    return resp;
  };

  getCurrentWeather = async (talhao) => {
    const resp = await this.authPost("/get-current-weather", { talhao }, {});
    return resp;
  };

  getWeatherForecast = async (talhao) => {
    const resp = await this.authPost("/get-weather-forecast", { talhao }, {});
    return resp;
  };

  getAnalysis = async () => {
    const resp = await this.authPost("/get-analysis", {}, {});
    return resp;
  };

  getAnalysisByUser = async () => {
    const resp = await this.authPost("/get-analysis-by-user", {}, {});
    return resp;
  };

  createOrUpdateAnalysis = async (analysis) => {
    const resp = await this.authPost(
      "/create-or-update-analysis",
      { analysis },
      {}
    );
    return resp;
  };

  getActivitiesByUser = async () => {
    const resp = await this.authPost("/get-activities-by-user", {}, {});
    return resp;
  };

  createOrUpdateActivity = async (activity) => {
    const resp = await this.authPost(
      "/create-or-update-activity",
      { activity },
      {}
    );
    return resp;
  };

  getAgroMonitoringNDVIImagesById = async (agromonitoring_id, date_start, date_end) => {
    const resp = await this.authPost(
      "/get-ndvi-images",
      { agromonitoring_id, date_start, date_end },
      {}
    );
    return resp;
  };

  getAgroMonitoringNDVIHistoryById = async (
    agromonitoring_id,
    date_start,
    date_end
  ) => {
    const resp = await this.authPost(
      "/get-ndvi-history",
      { agromonitoring_id, date_start, date_end },
      {}
    );
    return resp;
  };

  getLists = async () => {
    const resp = await this.authPost("/get-lists", {}, {});
    return resp;
  };

  uploadFile = async (new_file) => {
    const resp = await this.authPost(
      "/upload-file",
      { new_file },
      { multipart: true }
    );
    return resp;
  };

  recoveryPassword = async (email) => {
    const resp = await this.authPost(
      "/send-password-recovery",
      { email: email },
      {}
    );
    return resp;
  };

  changePassword = async (email, password, recovery_token) => {
    const resp = await this.authPost(
      "/change-password",
      { email, password, recovery_token },
      {}
    );
    return resp;
  };

  changeUserPassword = async (old_password, password) => {
    const resp = await this.authPost("/change-user-password", { old_password, password }, {});
    return resp;
  };

  checkErrorsWithStatusCode200 = (data) => {
    if (data.error) return data;
    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, {
        headers: { auth: this.getToken() },
        params: data,
      })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.inactivity) {
              emitToastr(
                "warning",
                err.response.data.message,
                "Sessão expirada"
              );
              setTimeout(() => {
                this.logout();
              }, 5000);
            } else {
              this.logout();
            }
          } else {
            emitToastr(
              "error",
              "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.",
              "Erro"
            );
          }
        } else {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.message
          )
            return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.inactivity) {
              emitToastr(
                "warning",
                err.response.data.message,
                "Sessão expirada"
              );
              setTimeout(() => {
                this.logout();
              }, 5000);
            } else {
              this.logout();
            }
          } else {
            emitToastr(
              "error",
              "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.",
              "Erro"
            );
          }
        } else {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.message
          ) {
            return err.response.data;
          } else if (err.response && err.response.status)
            emitToastr(
              "error",
              err.response.statusText +
              " (status: " +
              err.response.status +
              ")",
              "Erro"
            );
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
