<template>
    <div class="menu-page mt-9">
        <h1>Novo Talhão</h1>

        <div class="controls-container mt-4">

            <v-row>
                <v-col cols="12">
                    <gmap-autocomplete placeholder="Insira o endereço exato ou aproximado" @place_changed="setPlace" class="autocomplete">
                    </gmap-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-btn color="success" class="ma-2" @click.native="object.polygon = []">Reiniciar desenho</v-btn>
                <v-btn color="success" class="ma-2" @click.native="undoPolygon">Desfazer adição</v-btn>
                <v-btn color="success" class="ma-2" @click.native="dialogCreateUpdateObject = true">Salvar talhão</v-btn>
            </v-row>
        </div>
        <div class="map-container mt-4">
            <GmapMap :center="center" :zoom="15" map-type-id="hybrid" :options="gMapOptions" style="width: 100%; height: 70vh" ref="map" @click="clicked">
                <gmap-polygon :paths="object.polygon" :editable="true">
                </gmap-polygon>
            </GmapMap>
        </div>

        <v-dialog v-model="dialogCreateUpdateObject" persistent max-width="1000">
            <v-card>
                <v-card-title class="text-h5 mb-2">
                    <span>{{ object._id ? "Editar" : "Cadastrar" }} </span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click.native="dialogCreateUpdateObject = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>


                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="4" md="4" sm="12">
                            <v-text-field autocomplete="nope" dense v-model="object.property_name" label="Nome da propriedade" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="12">
                            <v-text-field autocomplete="nope" dense v-model="object.name" label="Nome do talhão" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="12">
                            <v-text-field autocomplete="nope" dense v-model="object.culture" label="Cultura (opcional)" outlined hide-details="auto"></v-text-field>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="success" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar talhão</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialogTip" max-width="400">
            <v-card>
                <v-card-title class="text-h5">
                    Instruções
                </v-card-title>
                <v-card-text>Primeiramente, encontre no mapa a área onde o talhão está localizado.</v-card-text>
                <v-card-text>Clique no mapa para adicionar pontos ao polígono que define o talhão. Você pode desfazer uma adição ou reiniciar o desenho. </v-card-text>
                <v-card-text>Após concluir o desenho do talhão, escolha um nome para ele e <b>salve antes de sair da página.</b></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="dialogTip = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import { emitToastr } from '@/lib/Utils';
import Api from "@/lib/Api";

export default {
    name: "TalhaoView",

    data() {
        return {
            gMapOptions: {
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            },
            edited: null,
            dialogTip: false,
            buttonLoading: false,
            object: { polygon: [] },
            dialogCreateUpdateObject: false,
            center: { lat: -22.62659, lng: -58.80959 }
        };
    },

    async mounted() {
        const user = await Api.getRemoteUser();
        if (user && !user.admin && user.plan == 'Gratuito') {
            emitToastr("error", "Acesso indisponível no plano gratuito");
            return this.$router.replace('/calendario');
        }
    },

    components: {},

    methods: {
        clicked(data) {
            this.object.polygon.push({ lat: data.latLng.lat(), lng: data.latLng.lng() })
        },

        undoPolygon() {
            this.object.polygon.pop();
        },

        setPlace(place) {
            if (place && place.geometry && place.geometry.location && place.geometry.location.lat) {
                this.center = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
            }
            else {
                emitToastr("warning", "Coordenadas não encontradas para este endereço. Insira um endereço próximo e encontre sua área manualmente no mapa.")
            }
        },

        async createOrUpdateObject() {
            this.buttonLoading = true;

            this.createGeoJson();
            const resp = await Api.createOrUpdateTalhao(this.object);


            if (resp && resp.message) {
                if (resp.error) {
                    emitToastr('error', resp.message);
                }
                else {
                    emitToastr('success', "Talhão cadastrado com sucesso!");
                    this.dialogCreateUpdateObject = false;
                    this.object = { polygon: [] }
                }
            }
            this.buttonLoading = false;
        },

        createGeoJson() {
            if (this.object.polygon) {
                let geoJSON = {
                    type: "Polygon",
                    coordinates: []
                };

                let polygonCoords = [];

                this.object.polygon.forEach(coord => {
                    polygonCoords.push([coord.lng, coord.lat]);
                })

                polygonCoords.push(polygonCoords[0]);

                geoJSON.coordinates.push(polygonCoords);
                this.object.geoJson = geoJSON;
            }
        }
    },
};
</script>
  
<style scoped>
.autocomplete {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 5px;
    padding: 10px;
}
</style>
  