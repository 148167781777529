<template>
    <div class="menu-page mt-9">
        <h1>Clima</h1>

        <!-- No data -->
        <div v-if="noData" class="fix-on-center mt-6">
            <p>Nenhum talhão cadastrado.</p>
            <router-link to="/novo-talhao">
                <v-btn color="primary">Cadastrar talhão</v-btn>
            </router-link>
        </div>

        <div class="mt-10" v-if="!noData">
            <v-row align="stretch">
                <v-col cols="12" lg="4" md="4" sm="12">
                    <v-card v-if="current_weather && current_weather.main" class="colored-card">
                        <v-card-title class="green-text">Dados do clima</v-card-title>
                        <v-card-text class="colored-card">
                            <h1>{{ kelvinToCelsius(current_weather.main.feels_like) }}°</h1>
                            <h5>Sensação térmica</h5>
                        </v-card-text>

                        <v-card-text class="colored-card">
                            <h1>{{ kelvinToCelsius(current_weather.main.temp_min) }}°</h1>
                            <h5>Temperatura mínima</h5>
                        </v-card-text>

                        <v-card-text class="colored-card">
                            <h1>{{ kelvinToCelsius(current_weather.main.temp_max) }}°</h1>
                            <h5>Temperatura máxima</h5>
                        </v-card-text>

                        <v-card-text class="colored-card">
                            <h1>{{ current_weather.main.humidity }}%</h1>
                            <h5>Umidade</h5>
                        </v-card-text>

                        <v-card-text class="colored-card">
                            <h1>{{ current_weather.main.sea_level }} hPa</h1>
                            <h5>Pressão nível do mar</h5>
                        </v-card-text>

                        <v-card-text class="colored-card">
                            <h1>{{ current_weather.main.grnd_level }} hPa</h1>
                            <h5>Pressão nível solo</h5>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="12" class="column" v-if="!loading">
                    <v-row class="height-100">
                        <v-col cols="12">
                            <v-card v-if="current_weather && current_weather.weather && current_weather.weather[0]" class="colored-card height-100">
                                <v-card-title class="green-text">Hoje</v-card-title>
                                <v-card-subtitle class="colored-card text-h4">{{
                                    getWeatherDescription(current_weather.weather[0].id) }}</v-card-subtitle>
                                <v-card-text class="colored-card">
                                    <div class="weather-row">
                                        <v-img class="weather-icon" :src="`https://openweathermap.org/img/wn/${current_weather.weather[0].icon}@4x.png`" contain></v-img>
                                        <h1>{{ kelvinToCelsius(current_weather.main.temp) }}°</h1>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card v-if="current_weather && current_weather.wind" class="colored-card height-100">
                                <v-card-title class="green-text">Vento</v-card-title>

                                <v-card-text class="colored-card">
                                    <h1>{{ current_weather.wind.speed }} m/s</h1>
                                    <h5>Velocidade</h5>
                                </v-card-text>

                                <v-card-text class="colored-card">
                                    <h1>{{ current_weather.wind.deg }} °</h1>
                                    <h5>Direção</h5>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="12">
                    <v-card class="colored-card height-100">
                        <v-card-title>Meus talhões</v-card-title>
                        <v-list class="colored-card">
                            <v-list-item-group v-model="model" mandatory color="success">
                                <v-list-item v-for="(item, i) in objects" :key="i" @click.native="selecionaTalhao(item)">
                                    <v-list-item-icon>
                                        <v-icon></v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title class="green-text">{{ item.name }} <small>({{
                                            item.agromonitoring_area.toFixed(2) }} ha)</small></v-list-item-title>
                                        <v-list-item-subtitle class="colored-card" v-text="item.property_name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="mt-2" v-if="!noData">
            <v-card class="colored-card">
                <v-card-title class="green-text">Previsão do tempo</v-card-title>
                <v-card-subtitle class="colored-card">{{ selectedObject.name }}</v-card-subtitle>
                <v-card-text v-if="loading_chart">
                    <v-skeleton-loader class="mx-auto" height="450" type="image"></v-skeleton-loader>
                </v-card-text>
                <v-card-text>
                    <div id="chart">
                        <apexChart :class="loading_chart ? 'hide' : ''" ref="weatherChart" height="450" type="line" :options="chartOptions" :series="series"></apexChart>
                    </div>
                </v-card-text>
            </v-card>


        </div>
    </div>
</template>
  
<script>
/* eslint-disable */
import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';

export default {
    name: "ClimaView",

    data() {
        return {
            objects: [],
            noData: false,
            model: 0,
            selectedObject: { name: "" },
            current_weather: [],
            weather_forecast: [],
            loading_chart: true,
            lists: [],
            loading: false,

            series: [],

            chartOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true,

                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                    labels: {
                        colors: ['#FFFFFF']
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false,
                    labels: {
                        style: {
                            colors: '#FFFFFF'
                        }
                    }

                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF'
                        }
                    }
                }
            },
        };
    },

    components: {},

    async created() {
        const user = await Api.getRemoteUser();
        if (user && !user.admin && user.plan == 'Gratuito') {
            emitToastr("error", "Acesso indisponível no plano gratuito");
            return this.$router.replace('/calendario');
        }

        this.loading = true;
        this.init();

        let resp = await Api.getLists();
        if (resp && !resp.error && resp.message) this.lists = resp.message.lista_geral;
        this.loading = false;
    },

    methods: {

        async init() {
            const resp = await Api.getTalhoesByUser();
            if (resp && !resp.error && resp.message) this.objects = resp.message;

            if (this.objects.length == 0) {
                this.noData = true;
            }
            else {
                this.noData = false;
            }

            this.paths = [];
            this.objects.forEach(obj => {
                if (obj.polygon) this.paths.push(obj.polygon);
            })

            if (this.objects.length > 0) {
                this.selecionaTalhao(this.objects[0]);
            }
        },

        getWeatherDescription(id) {
            return this.lists.weather_codes[id];
        },

        async selecionaTalhao(item) {
            this.selectedObject = item;
            const call_1 = Api.getCurrentWeather(this.selectedObject);
            const call_2 = Api.getWeatherForecast(this.selectedObject);

            const promisses = [await call_1, await call_2];

            if (promisses[0] && !promisses[0].error && promisses[0].message) {
                this.current_weather = promisses[0].message;
            }

            if (promisses[1] && !promisses[1].error && promisses[1].message) {
                this.weather_forecast = promisses[1].message;

                if (this.weather_forecast.length > 0) {
                    this.loading_chart = true;
                    let categories = [];
                    let temp_max_series = { name: "Temperatura máxima", data: [], enabled: false };
                    let temp_min_series = { name: "Temperatura mínima", data: [] };
                    let feels_like_series = { name: "Sensação térmica", data: [] };
                    let humidity_series = { name: "Umidade", data: [] };
                    let wind_speed_series = { name: "Velocidade do vento", data: [] };
                    let wind_deg_series = { name: "Direção do vento", data: [] };


                    this.weather_forecast.forEach(w => {
                        if (w.dt) categories.push(new Date(w.dt * 1000).toLocaleString());
                        if (w.main && w.main.feels_like) feels_like_series.data.push(this.kelvinToCelsius(w.main.feels_like));
                        if (w.main && w.main.humidity) humidity_series.data.push(w.main.humidity);
                        if (w.main && w.main.temp_max) temp_max_series.data.push(this.kelvinToCelsius(w.main.temp_max));
                        if (w.main && w.main.temp_min) temp_min_series.data.push(this.kelvinToCelsius(w.main.temp_min));
                        if (w.wind && w.wind.speed) wind_speed_series.data.push(w.wind.speed);
                        if (w.wind && w.wind.deg) wind_deg_series.data.push(w.wind.deg);
                    });

                    this.chartOptions.xaxis.categories = categories;
                    this.$refs.weatherChart.refresh();
                    this.series = [temp_max_series, temp_min_series, feels_like_series, humidity_series, wind_speed_series, wind_deg_series];

                    setTimeout(() => {
                        this.$refs.weatherChart.toggleSeries("Sensação térmica");
                        this.$refs.weatherChart.toggleSeries("Umidade");
                        this.$refs.weatherChart.toggleSeries("Velocidade do vento");
                        this.$refs.weatherChart.toggleSeries("Direção do vento");
                        this.loading_chart = false;
                    }, 150);
                }
            }

        },

        kelvinToCelsius(k) {
            return (k - 273.15).toFixed(1);
        }
    },
};

</script>
  
<style scoped>
.weather-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.weather-icon {
    max-width: 100px;
}

.colored-card {
    background-color: #042A41 !important;
    color: white !important
}

.green-text {
    color: #7aef93 !important;
}

.hide {
    opacity: 0;
}

.height-100 {
    height: 100%;
}

.column {
    display: flex;
    flex-direction: column;
}
</style>
  