<template>
  <div class="menu-page mt-9">
    <h1>Home</h1>

    <v-row class="mt-4">
      <!-- Image -->
      <v-col cols="12" lg="6" class="my-4 relative" v-if="!isDataEmpty">
        <!-- Loading -->
        <v-card-text v-if="loading_NDVIImages">
          <v-skeleton-loader v-for="(n, i) in 2" :key="i" class="mx-auto" max-width="1000" max-height="340" type="image"></v-skeleton-loader>
        </v-card-text>

        <!-- Data -->
        <v-carousel v-else-if="!loading_NDVIImages" height="400" class="relative" hide-delimiters>
          <v-carousel-item v-for="(item, i) in NDVIImages" :key="i" contain :src="item.image">
            <v-sheet style="
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translate(0%, 0%);
              " tile>
              <v-row align="center" justify="center">
                <strong>{{ convertTimeStampToDate(item.date) }}</strong>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>

      <!-- NDVI -->
      <v-col cols="12" lg="3" v-if="!isDataEmpty">
        <v-card height="440" class="service-card pa-1 fill-height d-flex flex-column" elevation="1">
          <v-card-title class="dont-break">NDVI</v-card-title>
          <v-card-subtitle v-if="!loading_NDVI && !isDataEmpty">
            <strong>
              {{ convertTimeStampToDate(selectedNDVI.dt) }}
            </strong>
          </v-card-subtitle>
          <v-divider></v-divider>

          <v-col class="relative">
            <!-- Loading NDVI -->
            <v-card-text v-if="loading_NDVI">
              <v-skeleton-loader v-for="(n, i) in 6" :key="i" class="mx-auto" max-width="400" width="100%" height="50" type="list-item"></v-skeleton-loader>
            </v-card-text>

            <!-- Data -->
            <v-col cols="12" v-if="!loading_NDVI && !isDataEmpty">
              <div>
                <v-list-item min-height="0">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <div>maximo</div>
                      <div>{{ selectedNDVI.data.max.toFixed(2) }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item min-height="20">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <div>média</div>
                      <div>{{ selectedNDVI.data.max.toFixed(2) }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item min-height="20">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <div>mediana</div>
                      <div>{{ selectedNDVI.data.median.toFixed(2) }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item min-height="20">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <div>minimo</div>
                      <div>{{ selectedNDVI.data.min.toFixed(2) }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item min-height="20">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <div>desvio</div>
                      <div>{{ selectedNDVI.data.min.toFixed(2) }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item min-height="20">
                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <div>num</div>
                      <div>{{ selectedNDVI.data.num }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-col>
          </v-col>
        </v-card>
      </v-col>

      <!-- Talhoes -->
      <v-col cols="12" lg="3">
        <v-card height="440" class="service-card pa-1 fill-height d-flex flex-column" elevation="1">
          <v-card-title class="dont-break">Talhões</v-card-title>
          <v-divider></v-divider>

          <v-col class="relative">
            <!-- Loading -->
            <v-card-text v-if="loading_talhoes">
              <v-skeleton-loader v-for="(n, i) in 6" :key="i" class="mx-auto" max-width="400" width="100%" height="50" type="list-item"></v-skeleton-loader>
            </v-card-text>

            <!-- No data -->
            <div v-if="isDataEmpty" class="fix-on-center">
              <p>Nenhum talhão cadastrado.</p>
              <router-link to="/novo-talhao">
                <v-btn color="primary">Cadastrar talhão</v-btn>
              </router-link>
            </div>

            <!-- Data -->
            <div v-else-if="!loading_talhoes">
              <v-data-table hide-default-header hide-default-footer dense :headers="[
                { text: 'nome', align: 'start', value: 'name' },
                {
                  text: 'valor',
                  align: 'start',
                  value: 'agromonitoring_area',
                },
              ]" :page.sync="dataTableConfig.page" @page-count="dataTableConfig.pageCount = $event" :items="talhoes" :items-per-page="dataTableConfig.itemsPerPage" item-key="_id">

              </v-data-table>

              <div class="text-center pt-2">

                <v-pagination v-model="dataTableConfig.page" :length="dataTableConfig.pageCount"></v-pagination>
              </div>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card min-height="400" class="relative">
          <v-card-title>Histórico NDVI</v-card-title>
          <v-row>
            <!-- Data -->
            <v-col v-if="!isDataEmpty" cols="12" class="d-flex justify-center align-center">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field autocomplete="nope" dense type="date" v-model="dateInicio" label="Início" outlined hide-details="auto" @change="getNDVIHistory(selectedTalhao.agromonitoring_id); getNDVIImages(selectedTalhao.agromonitoring_id)"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field autocomplete="nope" dense v-model="dateFim" type="date" label="Fim" outlined hide-details="auto" @change="getNDVIHistory(selectedTalhao.agromonitoring_id); getNDVIImages(selectedTalhao.agromonitoring_id)"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>


          </v-row>

          <v-row>
            <!-- Talhoes -->
            <v-col v-if="!isDataEmpty" cols="12" class="d-flex justify-center align-center">
              <!-- Loading Talhoes -->
              <v-card-text v-if="loading_talhoes">
                <v-skeleton-loader class="mx-auto" max-width="100%" height="50" type="image"></v-skeleton-loader>
              </v-card-text>

              <!-- Talhoes -->
              <v-sheet v-else class="mx-auto" elevation="0" max-width="100%">
                <v-slide-group v-model="model" class="pa-4" active-class="green" center-active mandatory show-arrows @change="onChangeTalhao">
                  <v-slide-item v-for="talhao in talhoes" :key="talhao._id" v-slot="{ active, toggle }">
                    <v-btn class="mx-2" :input-value="active" active-class="purple white--text" depressed rounded @click="toggle">
                      {{ talhao.name }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-col>
          </v-row>


          <!-- NDVI History -->
          <v-col>
            <!-- Loading NDVI History -->
            <v-card-text v-if="loading_NDVIHistory">
              <v-skeleton-loader v-for="(n, i) in 2" :key="i" class="mx-auto" width="100%" height="150" type="image"></v-skeleton-loader>
            </v-card-text>

            <!-- No data -->
            <div v-if="isDataEmpty" class="fix-on-center">
              <p>Nenhum talhão cadastrado.</p>
              <router-link to="/novo-talhao">
                <v-btn color="primary">Cadastrar talhão</v-btn>
              </router-link>
            </div>

            <!-- NDVI History -->
            <v-card-text v-else-if="!loading_NDVIHistory">
              <div id="chart">
                <apexChart :class="loading_NDVIHistory ? 'hide' : ''" ref="weatherChart" height="300" type="area" :options="chartOptions" :series="chartData"></apexChart>
              </div>
            </v-card-text>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
// import { emitToastr } from "@/lib/Utils";

export default {
  name: "DashboardView",

  data() {
    return {
      model: null,
      NDVIHistory: [],
      NDVIImages: [],
      talhoes: {},
      selectedTalhao: null,
      selectedNDVI: {},
      dateInicio: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
      dateFim: new Date().toISOString().substring(0, 10),
      dataTableConfig: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 8,
      },
      chartData: [
        {
          name: "Média",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          zoom: {
            enabled: true,
          },
          events: {
            markerClick: (
              event,
              chartContext,
              { seriesIndex, dataPointIndex, config }
            ) => {
              this.selectedNDVI = this.NDVIHistory[dataPointIndex];
            },
          },
        },
        colors: ["#00E396"],
        dataLabels: {
          enabled: true,
        },
        legend: {
          position: "top",
          fontSize: "16px",
          labels: {
            colors: ["#FFFFFF"],
          },
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          convertedCatToNumeric: false,
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        },
      },
      gMapOptions: {
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      isDataEmpty: false,
      loading_NDVI: true,
      loading_talhoes: true,
      loading_NDVIImages: true,
      loading_NDVIHistory: true,
    };
  },

  components: {},

  async mounted() {
    const user = await Api.getRemoteUser();

    //ESSE NÃO DÁ AVISO, DEIXA APENAS O CALENDÁRIO SER O ITEM INDEX DE ACESSO DO PLANO GRATUITO
    if (user && !user.admin && user.plan == 'Gratuito') {
      return this.$router.replace('/calendario');
    }
    this.getTalhoes();
  },

  methods: {
    async getTalhoes() {
      this.loading_talhoes = true;
      const resp = await Api.getTalhoesByUser();
      if (resp && !resp.error && resp.message) {
        this.talhoes = resp.message;
        this.selectedTalhao = resp.message[0];
        if (resp.message.length === 0) {
          this.isDataEmpty = true;
          this.onDataIsEmpty();
        } else {
          this.isDataEmpty = false;
        }
      }
      this.loading_talhoes = false;
    },

    async mountChart(agromonitoring_id) {
      this.getNDVIHistory(agromonitoring_id);
      this.getNDVIImages(agromonitoring_id);
    },

    async getNDVIHistory(agromonitoring_id) {
      this.loading_NDVIHistory = true;
      const resp = await Api.getAgroMonitoringNDVIHistoryById(
        agromonitoring_id,
        this.dateInicio,
        this.dateFim
      );
      if (resp && !resp.error && resp.message && resp.message.length > 0) {
        let categories = [];
        let values = [];

        resp.message.forEach((data) => {
          const value = data.data.median.toFixed(2);
          const category = this.convertTimeStampToDate(data.dt);
          values.push(value);
          categories.push(category);
        });

        this.chartData[0].data = values;
        this.NDVIHistory = resp.message;
        this.selectedNDVI = resp.message[0];
        this.chartOptions.xaxis.categories = categories;
        this.loading_NDVI = false;
      }

      this.loading_NDVIHistory = false;
    },

    async getNDVIImages(agromonitoring_id) {
      this.loading_NDVIImages = true;
      const resp = await Api.getAgroMonitoringNDVIImagesById(agromonitoring_id, this.dateInicio, this.dateFim);
      if (resp && !resp.error && resp.message) this.NDVIImages = resp.message;
      this.loading_NDVIImages = false;
    },

    convertTimeStampToDate(timestamp) {
      if (typeof (timestamp) == 'number') {
        timestamp = timestamp * 1000;
      }

      const date = new Date(timestamp);
      const formattedDate = date
        .toLocaleDateString("pt-BR", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/de/g, " ");
      return formattedDate;
    },

    onChangeTalhao(index) {
      this.mountChart(this.talhoes[index].agromonitoring_id);
    },

    onDataIsEmpty() {
      this.loading_NDVI = false;
      this.loading_talhoes = false;
      this.loading_NDVIImages = false;
      this.loading_NDVIHistory = false;
    },

    isEnabled(slot) {
      return this.enabled === slot;
    },
  },
};
</script>

<style scoped>
.fix-on-center {
  widows: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  text-align: center;
}
</style>
