<template>
    <div class="menu-page mt-9">
        <h1>Calendario</h1>

        <v-btn color="success" elevation="2" fixed bottom right fab @click.native="calendarClick"> <v-icon>mdi-plus</v-icon>
        </v-btn>

        <div class="mt-4">
            <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
                <v-tab v-for="item in items" :key="item">
                    {{ item }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>

                    <v-text-field v-model="search" append-icon="mdi-magnify" class="mb-4" label="Pesquisar" single-line
                        hide-details clearable></v-text-field>
                    <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto"
                        type="table"></v-skeleton-loader>

                    <v-data-table disable-sort v-if="!loading" :no-data-text="noDataText" :headers="headers"
                        :items="objects" :search="search" :items-per-page="50" class="elevation-1 pointer"
                        @click:row="showDrawer" :footer-props="{
                            'items-per-page-text': 'Linhas por página',
                            'items-per-page-all-text': 'Tudo',
                            'items-per-page-options': [5, 10, 30, 50, 100, -1],
                        }">

                        <template v-slot:[`item.initialDate`]="{ item }">
                            {{ new Date(item.initialDate).toLocaleDateString() }}
                        </template>

                        <template v-slot:[`item.finalDate`]="{ item }">
                            {{ new Date(item.finalDate).toLocaleDateString() }}
                        </template>

                        <template v-slot:[`item.items`]="{ item }">
                            <v-chip color="success" small v-for="(insumo_obj, index) in item.items" :key="index">{{
                                insumo_obj.insumo.nome }}</v-chip>
                        </template>

                        <template v-slot:[`item.area_ha`]="{ item }">
                            {{ item.area_ha.toFixed(2) }}
                        </template>



                    </v-data-table>

                </v-tab-item>

                <v-tab-item>
                    <v-btn fab text small color="grey darken-2" @click="prevWeek">
                        <v-icon small>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    Mudar semana
                    <v-btn fab text small color="grey darken-2" @click="nextWeek">
                        <v-icon small>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <v-calendar class="calendar" ref="weekCalendar" v-model="value" :value="value" :now="today"
                        :events="events" color="primary" type="week" locale="pt-BR" @click:date="calendarClick"
                        @click:time="calendarClick" @click:event="calendarEventClick"></v-calendar>
                </v-tab-item>

                <v-tab-item>
                    <v-btn fab text small color="grey darken-2" @click="prevMonth">
                        <v-icon small>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    Mudar mês
                    <v-btn fab text small color="grey darken-2" @click="nextMonth">
                        <v-icon small>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <v-calendar class="calendar" ref="monthCalendar" v-model="value" :value="value" :now="today"
                        :events="events" color="primary" type="month" locale="pt-BR" @click:day="calendarClick"
                        @click:date="calendarClick" @click:event="calendarEventClick">
                    </v-calendar>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition"
            class="details-container">
            <v-card v-if="lists && lists.lista_geral">
                <div class="container">
                    <v-card-title class="text-h5">
                        Nova Atividade
                    </v-card-title>
                    <v-card-text>
                        <v-row class="mt-4">
                            <v-col cols="12">
                                <div class="text-subtitle-1"><b>Área</b></div>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete prepend-icon="mdi-vector-polygon" :noDataText="noDataText"
                                    v-model="object.area" hide-details="auto" :items="talhoes" item-value="_id"
                                    item-text="name" return-object label="Selecione a área"
                                    @change="changeAreaPercentage"></v-autocomplete>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" lg="6" md="6" sm="6">
                                <v-text-field prepend-icon="mdi-map" v-model="object.area_percentage"
                                    label="Porcentagem (% ha)" hide-details="auto"
                                    @input="changeAreaPercentage"></v-text-field>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="6">
                                <v-text-field v-model="object.area_ha" label="Área (ha)" hide-details="auto"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete prepend-icon="mdi-tag" :noDataText="noDataText" v-model="object.activity"
                                    hide-details="auto" :items="lists.lista_geral.atividades" return-object
                                    label="Operação"></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete prepend-icon="mdi-check" :noDataText="noDataText" v-model="object.status"
                                    hide-details="auto" :items="statuses" return-object label="Status"></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" lg="6" md="6" sm="6">
                                <v-dialog ref="dialog1" v-model="modalInitialDate" :return-value.sync="initialDate"
                                    persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="initialDateFormatted" label="Data de início"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="object.initialDate" scrollable locale="pt-BR"
                                        v-on:input="dateSelected('initial')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalInitialDate = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary"
                                            @click="$refs.dialog1.save(initialDate); modalInitialDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="6">
                                <v-dialog ref="dialog2" v-model="modalFinalDate" :return-value.sync="finalDate" persistent
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="finalDateFormatted" label="Data de término"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="object.finalDate" locale="pt-BR" scrollable
                                        v-on:input="dateSelected('final')">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalFinalDate = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary"
                                            @click="$refs.dialog2.save(finalDate); modalFinalDate = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                        </v-row>

                        <div v-if="object.area_ha && object.activity">
                            <div
                                v-if="['Preparação de Solo', 'Tratamento de semente', 'Plantio', 'Fertilização', 'Outro'].includes(object.activity)">
                                <v-row class="mt-6">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Insumos</b></div>
                                    </v-col>
                                </v-row>


                                <v-row v-for="(item, index) in object.items" :key="index">
                                    <v-col cols="12">
                                        <v-card>
                                            <v-card-title>{{ item.insumo.nome }} <v-btn icon color="red"
                                                    @click.native="object.items.splice(index, 1)">
                                                    <v-icon>mdi-trash-can-outline</v-icon> </v-btn></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.type"
                                                            hide-details="auto" :items="lists.lista_geral.tipos_insumos"
                                                            label="Tipo" chips></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" v-if="item.type">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.insumo"
                                                            :filter="filterInsumo" hide-details="auto"
                                                            :items="lists.insumos.filter(i => i.tipo.toLowerCase() === item.type.toLowerCase())"
                                                            item-text="nome" label="Insumo" return-object chips>
                                                            <template v-slot:item="data">
                                                                <template>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title
                                                                            v-html="data.item.nome"></v-list-item-title>
                                                                        <v-list-item-subtitle
                                                                            v-html="data.item.ingrediente_ativo"></v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.insumo.ingrediente_ativo"
                                                            label="Ingrediente ativo" hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.insumo.titular_registro"
                                                            label="Titular de Registro" hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.insumo.concentracao"
                                                            label="Concentração" hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.insumo.classe_tox_ambiental"
                                                            label="Classe Toxicológica - Ambiental"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" lg="4" md="4" sm="4">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.unit"
                                                            hide-details="auto" :items="lists.lista_geral.unidades"
                                                            label="Unidade" chips
                                                            @change="changeAreaPercentage"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="4" md="4" sm="4" v-show="item.unit">
                                                        <v-text-field v-model="item.amount"
                                                            :label="`Quantidade (${item.unit}/ha)`" hide-details="auto"
                                                            @input="changeAmount(item)"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="4" md="4" sm="4" v-show="item.amount">
                                                        <v-text-field readonly v-model="item.total"
                                                            :label="`Total (${item.unit})`"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-btn color="success" @click.native="addItem">Adicionar insumo</v-btn>
                                    </v-col>
                                </v-row>
                            </div>


                            <div v-if="['Tratamento de semente', 'Plantio', 'Outro'].includes(object.activity)">
                                <v-row class="mt-10">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Cultura</b></div>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col cols="12" v-if="object.culture != null">
                                        <v-card>
                                            <v-card-title>{{ object.culture.item && object.culture.item.nome }} <v-btn icon
                                                    color="red" @click.native="delete object.culture">
                                                    <v-icon>mdi-trash-can-outline</v-icon> </v-btn></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText"
                                                            v-model="object.culture.type" hide-details="auto"
                                                            :items="lists.lista_geral.tipos_cultura" label="Tipo" chips
                                                            @change="$forceUpdate()"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete v-if="object.culture.type" :noDataText="noDataText"
                                                            v-model="object.culture.item" hide-details="auto"
                                                            :items="lists.culturas.filter(i => i.tipo.toLowerCase() === object.culture.type.toLowerCase())"
                                                            item-text="nome" label="Cultura" return-object chips
                                                            @change="$forceUpdate()"></v-autocomplete>
                                                    </v-col>
                                                </v-row>

                                                <v-row v-if="object.culture.item">
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="object.culture.item.titular_registro"
                                                            label="Titular de Registro" hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="object.culture.item.porte" label="Porte"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="object.culture.item.copa" label="Copa"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="object.culture.item.produtividade"
                                                            label="Produtividade" hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="object.culture.item.maturacao"
                                                            label="Maturação" hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="object.culture.item.resistencia_ferrugem"
                                                            label="Resistência à ferrugem"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                </v-row>


                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row v-if="!object.culture">
                                    <v-col cols="12">
                                        <v-btn color="success"
                                            @click.native="object.culture = { item: { nome: 'Nova cultura' } }; $forceUpdate()">Adicionar
                                            cultura</v-btn>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="['Monitoramento', 'Outro'].includes(object.activity)">
                                <v-row class="mt-10">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Pragas e doenças</b></div>
                                    </v-col>
                                </v-row>

                                <v-row v-for="(item, index) in object.pests" :key="index">
                                    <v-col cols="12">
                                        <v-card>
                                            <v-card-title>{{ item.pest.nomes }} <v-btn icon color="red"
                                                    @click.native="object.pests.splice(index, 1)">
                                                    <v-icon>mdi-trash-can-outline</v-icon> </v-btn></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.culture"
                                                            hide-details="auto" :items="lists.lista_geral.tipos_cultura"
                                                            label="Cultura" chips @change="$forceUpdate()"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.type"
                                                            hide-details="auto" :items="lists.lista_geral.tipos_praga"
                                                            label="Tipo de praga" chips
                                                            @change="$forceUpdate()"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6" v-if="item.type">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.pest"
                                                            :filter="filterPest" hide-details="auto"
                                                            :items="lists.pragas.filter(i => i.tipo.toLowerCase() === item.type.toLowerCase())"
                                                            item-text="nomes" label="Praga ou Doença" return-object chips>
                                                            <template v-slot:item="data">
                                                                <template>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title
                                                                            v-html="data.item.nome_cientifico"></v-list-item-title>
                                                                        <v-list-item-subtitle
                                                                            v-html="data.item.nomes"></v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText"
                                                            v-model="item.monitoring_scale" hide-details="auto"
                                                            :items="lists.lista_geral.praga_escala_monitoramento"
                                                            label="Escala de monitoramento" chips
                                                            @change="$forceUpdate()"></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.incidence" label="Incidência"
                                                            type="number" hide-details="auto"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-btn color="success" @click.native="addPest">Adicionar praga ou doença</v-btn>
                                    </v-col>
                                </v-row>
                            </div>


                            <div>
                                <v-row class="mt-10">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Serviços</b></div>
                                    </v-col>
                                </v-row>

                                <v-row v-for="(item, index) in object.services" :key="index">
                                    <v-col cols="12">
                                        <v-card>
                                            <v-card-title>{{ item.name }} <v-btn icon color="red"
                                                    @click.native="object.services.splice(index, 1)">
                                                    <v-icon>mdi-trash-can-outline</v-icon> </v-btn></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.name" label="Nome do serviço"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.unit"
                                                            hide-details="auto" :items="lists.lista_geral.servico_unidades"
                                                            label="Unidade" chips></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.amount" label="Quantidade" type="number"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-btn color="success" @click.native="addService">Adicionar serviço</v-btn>
                                    </v-col>
                                </v-row>
                            </div>

                            <div>
                                <v-row class="mt-10">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Itens</b></div>
                                    </v-col>
                                </v-row>

                                <v-row v-for="(item, index) in object.others" :key="index">
                                    <v-col cols="12">
                                        <v-card>
                                            <v-card-title>{{ item.name }} <v-btn icon color="red"
                                                    @click.native="object.others.splice(index, 1)">
                                                    <v-icon>mdi-trash-can-outline</v-icon> </v-btn></v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.type"
                                                            hide-details="auto" :items="lists.lista_geral.tipos_item"
                                                            label="Tipo" chips></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.name" label="Nome do item"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.description" label="Descrição"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.supplier" label="Fornecedor"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-autocomplete :noDataText="noDataText" v-model="item.unit"
                                                            hide-details="auto" :items="lists.lista_geral.unidades"
                                                            label="Unidade" chips></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" lg="6" md="6" sm="6">
                                                        <v-text-field v-model="item.amount" label="Quantidade" type="number"
                                                            hide-details="auto"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <v-btn color="success" @click.native="addOther">Adicionar item</v-btn>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="object.area_ha && object.activity">
                                <v-row class="mt-10">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Equipe</b></div>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-combobox v-model="object.team" label="Equipe" multiple chips>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <v-chip v-bind="attrs" :input-value="selected">
                                                    <v-avatar class="accent white--text" left
                                                        v-text="item.slice(0, 1).toUpperCase()"></v-avatar>
                                                    <span class="pr-2">
                                                        {{ item }}
                                                    </span>
                                                    <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                                </v-chip>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="object.area_ha && object.activity">
                                <v-row class="mt-6">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Máquinas</b></div>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-combobox v-model="object.machines" label="Máquinas" multiple chips>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <v-chip v-bind="attrs" :input-value="selected">
                                                    <v-avatar class="accent white--text" left
                                                        v-text="item.slice(0, 1).toUpperCase()"></v-avatar>
                                                    <span class="pr-2">
                                                        {{ item }}
                                                    </span>
                                                    <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                                </v-chip>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="object.area_ha && object.activity">
                                <v-row class="mt-6">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1"><b>Informações complementares</b></div>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-textarea outlined v-model="object.obs" label="Observações" rows="8"></v-textarea>
                                    </v-col>
                                </v-row>
                            </div>

                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = false">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="createOrUpdateObject">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>



        <v-navigation-drawer class="activity-drawer" v-model="sheet" app right temporary>
            <div v-show="object.area">
                <GmapMap :center="center" :zoom="15" map-type-id="hybrid" :options="gMapOptions"
                    style="width: 100%; height: 30vh" ref="map">
                    <gmap-polygon :paths="paths">
                    </gmap-polygon>
                </GmapMap>
            </div>

            <div class="ma-2">
                <div>
                    <center>
                        <v-btn-toggle v-model="object.status" mandatory dense center color="success accent-3" group
                            @change="createOrUpdateObject">
                            <v-btn small value="A fazer" class="pa-1">
                                A fazer
                            </v-btn>

                            <v-btn small value="Em progresso" class="pa-1">
                                Em progresso
                            </v-btn>

                            <v-btn small value="Revisar" class="pa-1">
                                Revisar
                            </v-btn>

                            <v-btn small value="Concluída" class="pa-1">
                                Concluída
                            </v-btn>
                        </v-btn-toggle>
                    </center>
                </div>
                <div class="text-h6">{{ object.activity }}</div>
                <div class="text-body"><b>Data de início: </b>{{ new Date(object.initialDate).toLocaleDateString() }}</div>
                <div class="text-body"><b>Data de término: </b>{{ new Date(object.finalDate).toLocaleDateString() }}</div>

                <v-list>
                    <div v-if="object.items && object.items.length > 0">
                        <v-list-item three-line v-for="(item, index) in object.items" :key="index">
                            <v-list-item-content class="pa-1">
                                <v-list-item-title><b>Insumos</b></v-list-item-title>
                                <v-list-item-subtitle>{{ item.insumo.nome }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ item.amount }} {{ item.unit }} / ha - Total: {{ item.total }} {{
                                    item.unit }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <v-list-item three-line v-if="object.culture && object.culture.type">
                        <v-list-item-content class="pa-1">
                            <v-list-item-title><b>Cultura</b></v-list-item-title>
                            <v-list-item-subtitle>{{ object.culture.type }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="object.culture.item && object.culture.item.nome">{{
                                object.culture.item.nome }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <div v-if="object.pests && object.pests.length > 0">
                        <v-list-item three-line v-for="(item, index) in object.pests" :key="index">
                            <v-list-item-content class="pa-1">
                                <v-list-item-title><b>Pragas e doenças</b></v-list-item-title>
                                <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.pest && item.pest.nomes">{{ item.pest.nomes
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <div v-if="object.services && object.services.length > 0">
                        <v-list-item three-line v-for="(item, index) in object.services" :key="index">
                            <v-list-item-content class="pa-1">
                                <v-list-item-title><b>Serviços</b></v-list-item-title>
                                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.unit && item.amount">{{ item.amount }} {{ item.unit
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>

                    <div v-if="object.others && object.others.length > 0">
                        <v-list-item three-line v-for="(item, index) in object.others" :key="index">
                            <v-list-item-content class="pa-1">
                                <v-list-item-title><b>Itens</b></v-list-item-title>
                                <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.name">{{ item.name }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.unit && item.amount">{{ item.amount }} {{ item.unit
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list>





                <v-btn block class="success mt-2" @click="calendarEventClick({ event: { id: object._id } })">Ver
                    detalhes</v-btn>
            </div>
        </v-navigation-drawer>

    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "CalendarioView",

    data() {
        return {
            tab: null,
            loading: false,
            search: "",
            value: "",
            talhoes: [],
            lists: {},
            objects: [],
            object: { area_percentage: 100, status: "A fazer", items: [], pests: [], services: [], others: [], culture: null },
            items: [
                'Lista', 'Semana', 'Mês',
            ],
            statuses: [
                'A fazer', 'Em progresso', 'Revisar', 'Concluída'
            ],
            noDataText: "Sem dados",
            dialog: false,
            sheet: false,
            initialDate: '',
            modalInitialDate: false,
            finalDate: '',
            last_event_click: 0,
            modalFinalDate: false,
            initialDateFormatted: undefined,
            finalDateFormatted: undefined,
            headers: [
                { text: "Progresso", value: "status" },
                { text: "Tipo", value: "activity" },
                { text: "Data de início", value: "initialDate" },
                { text: "Data de término", value: "finalDate" },
                { text: "Área", value: "area.name" },
                { text: "ha", value: "area_ha" },
                { text: "Insumos", value: "items" },
            ],

            events: [],

            gMapOptions: {
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            },
            paths: [[]],
            center: { lat: -22.62659, lng: -58.80959 },
        };
    },

    components: {},

    async created() {
        this.loading = true;
        this.today = new Date().toISOString().substring(0, 10);
        let resp = await Api.getLists();
        if (resp && !resp.error && resp.message) this.lists = resp.message;

        resp = await Api.getTalhoesByUser();
        if (resp && !resp.error && resp.message) this.talhoes = resp.message;

        await this.init();
        this.loading = false;
    },

    methods: {

        async init() {
            this.object = { area_percentage: 100, status: "A fazer", items: [], pests: [], services: [], others: [], culture: null };

            const resp = await Api.getActivitiesByUser();
            if (resp && !resp.error && resp.message) {
                this.objects = resp.message;

                this.events = [];

                if (this.objects.length > 0) {
                    this.objects.forEach(event => {
                        const area_name = (event.area && event.area.name) ? event.area.name : 'Talhão apagado';

                        this.events.push({
                            name: `${area_name} - ${event.activity}`,
                            start: new Date(event.initialDate).getTime(),
                            end: new Date(event.finalDate).getTime(),
                            id: event._id
                        })
                    })
                }
            }
        },

        calendarClick(data, item, item2) {
            if (new Date().getTime() - this.last_event_click > 2000) {
                this.object = { area_percentage: 100, status: "A fazer", items: [], pests: [], services: [], others: [], culture: null };
                this.dialog = true;
            }

        },

        calendarEventClick(data) {
            this.last_event_click = new Date().getTime(); //CORREÇÃO DO BUG DE CLIQUE NO EVENTO JUNTO COM CLIQUE NO DIA

            if (data && data.event && data.event.id) {
                this.object = this.objects.find(o => o._id == data.event.id);
                if (this.object) {
                    this.object.initialDate = new Date(this.object.initialDate).toISOString().substring(0, 10)
                    this.initialDateFormatted = new Date(this.object.initialDate).toISOString().substring(0, 10);
                    this.object.finalDate = new Date(this.object.finalDate).toISOString().substring(0, 10);
                    this.finalDateFormatted = new Date(this.object.finalDate).toISOString().substring(0, 10);
                    this.dialog = true;
                }
            }
        },

        prevWeek() {
            this.$refs.weekCalendar.prev();
        },

        nextWeek() {
            this.$refs.weekCalendar.next();
        },

        prevMonth() {
            this.$refs.monthCalendar.prev();
        },

        nextMonth() {
            this.$refs.monthCalendar.next();
        },

        changeAreaPercentage() {
            if (this.object.area_percentage > 100) {
                this.object.area_percentage = 100;
                return emitToastr("warning", "O valor máximo é 100%");
            }

            if (this.object.area_percentage < 0) {
                this.object.area_percentage = 0;
                return emitToastr("warning", "O valor mínimo é 0%");
            }

            this.object.area_ha = (this.object.area.agromonitoring_area * (this.object.area_percentage / 100)).toFixed(4);
        },

        dateSelected(type) {
            if (type === 'initial') {
                const splitedDate = this.object.initialDate.split('-');
                this.initialDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
            else {
                const splitedDate = this.object.finalDate.split('-');
                this.finalDateFormatted = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
            }
        },

        changeAmount(item) {
            if (item.amount < 0) return emitToastr("warning", "O valor mínimo é 0");
            item.total = (item.amount * this.object.area_ha).toFixed(2);
        },

        addItem() {
            if (!this.object.items) this.object.items = [];
            this.object.items.push({ insumo: { nome: "Novo insumo" } })
            this.$forceUpdate();
        },

        addPest() {
            if (!this.object.pests) this.object.pests = [];
            this.object.pests.push({ pest: { nome: "Nova praga" } })
            this.$forceUpdate();
        },

        addService() {
            if (!this.object.services) this.object.services = [];
            this.object.services.push({ name: "Novo serviço" })
            this.$forceUpdate();
        },

        addOther() {
            if (!this.object.others) this.object.others = [];
            this.object.others.push({ name: "Novo item" })
            this.$forceUpdate();
        },

        async createOrUpdateObject() {
            if (!this.object.initialDate || !this.object.finalDate) return emitToastr("warning", "Data inicial e final são obrigatórias, mesmo que sejam estimativas.");
            this.loading = true;
            const resp = await Api.createOrUpdateActivity(this.object);

            if (resp && !resp.error && resp.message) {
                emitToastr("success", "Operação executada com sucesso.")
                this.dialog = false;
                this.sheet = false;
            }

            if (resp && resp.error) {
                emitToastr("error", resp.message)
            }

            this.loading = false;
            this.dialog = false;
            this.init();
        },

        showDrawer(data) {
            this.object = this.objects.find(o => o._id == data._id);

            if (data.area && data.area.polygon) {
                var bounds = new google.maps.LatLngBounds();

                for (var i = 0; i < data.area.polygon.length; i++) {
                    var point = new google.maps.LatLng(data.area.polygon[i].lat, data.area.polygon[i].lng);
                    bounds.extend(point);
                }

                this.paths = data.area.polygon

                this.$refs.map.fitBounds(bounds);
            }

            this.sheet = true;
        },

        filterInsumo(item, queryText, itemText) {
            return (
                item.nome.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
                -1 ||
                item.ingrediente_ativo.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },

        filterPest(item, queryText, itemText) {
            return (
                item.nome_cientifico.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
                -1 ||
                item.nomes.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            );
        }
    },
};
</script>
  
<style scoped>
.calendar {
    min-height: 750px;
    cursor: pointer;
}

.container {
    max-width: 800px;
}

.details-container {
    width: 100vw;
    overflow-x: hidden;
}

.activity-drawer {
    width: 80vw !important;
    max-width: 450px !important;
}
</style>
  