<script>

export default {
    data: () => ({
        showMenu: false,
        showHeaderOptions: false
    }),
    mounted() {
        if (this.$route.path === '/') this.showHeaderOptions = true;
    },
    methods: {
        openMenu() {
            this.showMenu = true;
        },
        closeMenu() {
            this.showMenu = false
        }
    },
    computed: {
        pages() {
            const pages = [
                { ref: 'solutionsSection', title: "Soluções" },
                { ref: 'pricingSection', title: "Preços" },
                { ref: 'aboutSection', title: "Sobre nós" },
                { ref: 'contactSection', title: "Contato" },
            ]

            for (let page of pages) {
                if (page.path == this.$route.path) {
                    page.selected = true
                }
            }

            return pages;
        }
    },
}

</script>
<template>
    <header class="section-app white app-shadow" id="header">
        <div class="section-app-content d-flex justify-center align-center">
            <h1 class="pointer" @click="$router.push('/')">
                <span style="color: transparent" class="absolute">Terraform Brasil</span>
                <img id="logo" class="logo" src="@/assets/logo_blue.png" alt="Logotipo da Terraform Brasil" />
            </h1>
            <div class="flex-grow-1">
            </div>
            <nav class="hidden-sm-and-down" v-if="showHeaderOptions">
                <ul>
                    <li :key="page.path" v-for="page in pages" class="font-weight-bold pointer" @click="$root.$refs[page.ref].scrollIntoView({ block: 'start', behavior: 'smooth' });">
                        {{ page.title }}
                    </li>
                </ul>
            </nav>
            <div class="flex-grow-1">
            </div>
            <AppButton class="text-base" @click.native="$router.push('/login')">
                Entrar
            </AppButton>

            <AppButton class="text-base hidden-sm-and-down ml-2" @click.native="$router.push('/login')">
                Teste Gratuitamente
            </AppButton>
            <!--
                <button @click="openMenu">
                    <img class="hidden-md-and-up" src="@/assets/burger.svg" id="burger" />
                </button>
            -->
        </div>
        <v-dialog v-model="showMenu" fullscreen hide-overlay transition="slide-x-reverse-transition">
            <div class="primary w-ful h-full">
                <div class="d-flex align-center justify-space-between w-full px-4 py-5" style="height: 7.5rem;">
                    <img id="logo-white" class="logo" src="@/assets/logo_white.png" alt="Logotipo da Terraform Brasil" />
                    <button id="close" @click="closeMenu">
                        <img src="@/assets/icon_dismiss.svg" class="w-full h-full" />
                    </button>
                </div>
            </div>
        </v-dialog>
    </header>
</template>
<style scoped>
#header {
    z-index: 50;
    height: 7.5rem;
    position: fixed;
    top: 0 !important;
}

.logo {
    width: 15rem;
}

#burger,
#close {
    width: 2rem;
    height: 2rem;
}

nav {
    width: 21.25rem;
}

nav>ul {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    padding: 0px;
}

nav>ul>li>a {
    color: #9497A1;
    font-weight: 500;
    letter-spacing: -0.01em;
}

nav>ul>li>a.selected {
    color: #292D33;
    font-weight: bold;
}
</style>