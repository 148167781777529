<template>
    <div class="login_container">
        <div class="left_container"></div>


        <div class="right_container">
            <div class="logo mb-10 pointer" @click="$router.push('/')">
                <v-img src="@/assets/leafmap_logo.png"></v-img>
            </div>

            <div class="form">
                <div v-if="isLogin">
                    <label>Faça o login em sua conta</label>
                    <v-text-field class="mb-4 mt-6" v-model="email" label="Usuário" outlined hide-details="auto"></v-text-field>
                    <v-text-field class="mb-4" v-model="password" type="password" label="Senha" outlined hide-details="auto"></v-text-field>
                    <v-checkbox v-model="remember" class="mb-8" hide-details color="#171717">
                        <template v-slot:label>
                            <div>Lembre-se de mim</div>
                        </template>
                    </v-checkbox>
                    <v-btn class="default-button" rounded block color="#171717" @click.native="login">
                        Entrar
                    </v-btn>

                    <div class="mt-8">
                        <label class="text-green pointer noselect" @click="isLogin = !isLogin">Cadastre-se gratuitamente</label>
                    </div>

                    <div class="mt-2">
                        <label class="pointer noselect" @click="password_recovery_dialog = true">Esqueceu a senha?</label>
                    </div>
                </div>

                <div v-if="!isLogin">
                    <label>Crie sua conta</label>
                    <v-text-field class="mb-4 mt-6" v-model="new_user.name" label="Nome" outlined hide-details="auto"></v-text-field>
                    <v-text-field class="mb-4" v-model="new_user.last_name" label="Sobrenome" outlined hide-details="auto"></v-text-field>
                    <v-text-field class="mb-4" v-model="new_user.email" label="E-mail" outlined hide-details="auto"></v-text-field>
                    <v-text-field class="mb-4" v-model="new_user.phone" v-mask="'(##) #########'" label="Telefone de contato" outlined hide-details="auto"></v-text-field>
                    <v-text-field class="mb-4" v-model="new_user.password" type="password" label="Senha" outlined hide-details="auto"></v-text-field>
                    <v-btn class="default-button" rounded block color="#171717" @click.native="createUser">
                        Cadastrar
                    </v-btn>

                    <div class="mt-8">
                        <label class="pointer noselect mr-2">Já possui uma conta?</label>
                        <label class="text-green pointer noselect" @click="isLogin = !isLogin">Faça seu login</label>
                    </div>
                </div>

            </div>
        </div>

        <v-dialog v-model="password_recovery_dialog" max-width="420">
            <v-card>
                <v-card-title class="text-h5">
                    Recuperação de senha
                </v-card-title>
                <v-card-text>Ao clicar em continuar, você receberá um e-mail com instruções de recuperação de senha em sua caixa de entrada.</v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field class="mb-4" v-model="password_recovery_email" label="E-mail cadastrado" outlined hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="password_recovery_dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" text @click="password_recovery_dialog = false; sendRecoveryEmail()">
                        Continuar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",

    data() {
        return {
            email: "",
            password: "",
            remember: true,
            isLogin: true,
            new_user: {
                name: "",
                last_name: "",
                email: "",
            },
            password_recovery_dialog: false,
            password_recovery_email: ''
        };
    },

    components: {},

    mounted() {
        const token = Api.getToken();
        if (token) this.$router.push("/dashboard");
    },

    methods: {
        async login() {
            const resp = await Api.login(this.email, this.password, this.remember);
            if (resp && resp.login_ok) {
                this.$root.$refs.global.updateUser(resp.user);
                this.$router.push("/dashboard");
                return;
            }

            if (resp && resp.error && resp.message) {
                emitToastr('error', resp.message)
                return;
            }
        },

        async sendRecoveryEmail() {
            const resp = await Api.recoveryPassword(this.password_recovery_email);
            this.password_recovery_email = '';
            if (resp && !resp.error) {
                emitToastr('success', 'E-mail enviado com sucesso. Por favor verifique sua caixa de entrada.')
            }
            else {
                if (resp.message) emitToastr('error', resp.message)
            }
        },

        async createUser() {
            if (!this.new_user.name) return emitToastr("error", "Nome é um campo requerido");
            if (!this.new_user.last_name) return emitToastr("error", "Sobrenome é um campo requerido");
            if (!this.new_user.email) return emitToastr("error", "E-mail é um campo requerido");
            if (!this.new_user.phone) return emitToastr("error", "Telefone de contato é um campo requerido");
            if (!this.new_user.password) return emitToastr("error", "Senha é um campo requerido");
            if (this.new_user.password.length < 6) return emitToastr("error", "Senha precisa ter pelo menos 6 dígitos");

            const resp = await Api.createUser(this.new_user.email, this.new_user.password, this.new_user.name, this.new_user.last_name, this.new_user.phone);
            if (resp == true) {
                this.$root.$refs.global.user = Api.getUser();
                this.$router.push("/dashboard");
            }
        }

    },
};
</script>
  
<style scoped>
.login_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    align-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100%;
}

.left_container {
    width: 60vw;
    height: 100%;
    max-height: 100%;
    background-image: url('@/assets/login_frame.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: -2px 0px 12px rgba(0, 0, 0, 0.6);
}

.right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 40vw;
}

.form {
    width: 350px;
    text-align: center;
}

@media only screen and (max-width: 1250px) {
    .left_container {
        width: 55vw;
    }

    .right_container {
        width: 45vw;
    }

    .form {
        width: 350px;
        text-align: center;
    }
}

@media only screen and (max-width: 900px) {
    .left_container {
        width: 0vw;
        display: none;
    }

    .right_container {
        width: 100vw;
    }

    .form {
        width: 350px;
        text-align: center;
    }
}
</style>
  