<script>

export default {
}

</script>
<template>
    <footer class="section-app bright-blue" id="footer">
        <div class="section-app-content">
            <div
                class="d-flex justify-space-between flex-column flex-sm-row align-start pb-4 pb-sm-10 w-full">
                <img id="logo" src="@/assets/logo_blue.png" class="mb-4 mb-sm-0 mr-sm-12" alt="Logotipo da Terraform Brasil" />
                <!--
                <nav>
                    <ul>
                        <li class="mb-2">
                            <h3 class="text-base-2 font-weight-bold black--text">About</h3>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Home</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">About Us</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Services</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Solutions</a>
                        </li>
                    </ul>
                    <ul>
                        <li class="mb-2">
                            <h3 class="text-base-2 font-weight-bold black--text">Information</h3>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Contacts</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Our Teams</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Blog</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">FAQ</a>
                        </li>
                    </ul>
                    <ul>
                        <li class="mb-2">
                            <h3 class="text-base-2 font-weight-bold black--text">Service</h3>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Pages</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Elements</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Site map</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">Pricing</a>
                        </li>
                        <li class="mb-2">
                            <a class="text-base">FAQ</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="d-flex justify-space-between align-start pt-5 pt-sm-10 pb-7 pb-sm-4 w-full"
                style="max-width: 100vw">
                <div>
                    <h3 class="text-base-2 black--text font-weight-bold mb-4 mb-sm-7">Contato</h3>
                    <div class="text-base mb-sm-2">+55 27992246472</div>
                    <div class="text-base d-flex align-center">
                        <span class="font-weight-semibold">contato@terraformbrasil.com</span><img class="w-5 h-5 ml-1"
                            src="@/assets/icon_arrow_right.svg" />
                    </div>
                </div>
                <nav>
                    <div></div>
                    <div>
                        <h3 class="text-base-2 black--text font-weight-bold mb-4">Social</h3>
                        <div class="d-flex social-links">
                            <a>
                                <img class="social" src="@/assets/icon_facebook.svg" />
                            </a>
                            <a>
                                <img class="social" src="@/assets/icon_twitter.svg" />
                            </a>
                            <a>
                                <img class="social" src="@/assets/icon_linkedin.svg" />
                            </a>
                        </div>
                    </div>
                    <div></div>
                </nav>
                -->
            </div>
            <div
                class="d-flex flex-column flex-sm-row align-start  align-sm-center w-full py-sm-7 py-3 gray--text text-base font-weight-semibold">
                <a class="gray--text mr-11 d-flex align-center">Politica de Privacidade<img class="w-5 h-5 ml-1"
                        src="@/assets/icon_arrow_right-white.svg" /></a>
                <a class="gray--text  d-flex align-center">Termos de uso<img class="w-5 h-5 ml-1"
                        src="@/assets/icon_arrow_right-white.svg" /></a>
                <div class="flex-grow-1" />
                <div>© 2023 Terraform Brasil. Todos os direitos reservados.</div>
            </div>
        </div>
    </footer>
</template>
<style scoped>
footer {
    padding-top: 2rem;
}

@media screen and (min-width: 960px) {
    footer {
        padding-top: 3.25rem;
    }
}

#logo {
    height: 3.75rem;
}

nav {
    display: flex;
    max-width: 22.75rem;
}

nav>ul {
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 600px) {
    nav>ul {
        margin: 0px;
        align-items: start;
    }
}

@media screen and (min-width: 480px) {
    nav {
        display: grid;
        grid-template-columns: 6fr 6fr 6fr;
        column-gap: 1rem;
    }
}

@media screen and (min-width: 960px) {
    nav {
        max-width: 27.75rem;
    }
}

nav>ul {
    list-style: none;
    padding: 0px;
}

footer>.section-app-content>div:not(:last-child) {
    border-bottom: 1px solid #C9DCEC;
}

a {
    color: #000000 !important;
}

.social {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
}

.social-links {
    width: 10rem;
}

@media screen and (min-width: 600px) {
    .social {
        width: 1.5rem;
        height: 1.5rem;
    }

    .social-links {
        width: 6.5rem;
    }
}
</style>