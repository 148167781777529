<script>
import SectionContact from "../components/sections/SectionContact.vue"
import ArticleDoc from "@/components/ArticleDoc.vue"
const content = require("../content/cases.json")

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Project",
    components: { SectionContact, ArticleDoc },
    mounted() {

    },
    computed: {
        currentCase() {
            return content[this.$route.params.id]
        }
    }
}
</script>
<template>
    <div class="menu-page">
        <AppHeader />
        <template v-if="currentCase">
            <section id="hero" class="section-app" :style="{ 'background-image': `url('${require('@/assets/' + currentCase.hero_img)}')` }">
                <div class="section-app-content">
                    <div class="title-wrapper d-flex justify-center align-center w-full">
                        <h1 class="bright-blue--text text-3xl font-weight-black text-center">{{ currentCase.title }}</h1>
                    </div>
                    <div class="w-full bright-blue d-flex flex-column align-center app-shadow" id="tags">
                        <h2 class="black--text text-xl pb-3 mb-5 font-weight-black text-center">Captura</h2>
                        <div class="w-full d-flex flex-column flex-md-row align-center justify-center">
                            <div class="d-flex align-stretch tag" :key="i" v-for="(t, i) in currentCase.tags">
                                <div class="tag-image d-flex justify-center">
                                    <img :src="require('@/assets/' + t.image)" class="h-full" />
                                </div>
                                <div class="d-flex flex-column justify-center ml-4 ml-sm-9 tag-details">
                                    <h3 class="black--text text-lg">{{ t.title }}</h3>
                                    <p class="gray--text text-base-2 mb-0">{{ t.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="white section-app" id="intro">
                <div class="section-app-content">
                    <h2 class="black--text font-weight-black text-2xl mb-8">{{ currentCase.intro.title }}</h2>
                    <p class="gray--text text-lg mb-14" v-html="currentCase.intro.description"></p>
                    <div class="secondary-dark px-9 py-7 app-radius app-shadow">
                        <div :key="topic.title" v-for="(topic, i) in currentCase.topics" :class="i !== 0 ? 'mt-5' : ''">
                            <h3 class="bright-blue--text text-lg-2 font-weight-bold mb-5">{{ topic.title }}</h3>
                            <p class="text-base-2 bright-blue--text mb-0">{{ topic.description }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="article" class="section-app" v-if="currentCase.articles">
                <div class="section-app-content">
                    <ArticleDoc :article="article" v-for="article in currentCase.articles" :key="article.title" />
                </div>
            </section>
        </template>
        <SectionContact />
        <AppFooter />
    </div>
</template>
<style scoped>
#hero {
    width: 100%;
    height: 36.875rem;
    padding-top: 7.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#hero>.section-app-content>.title-wrapper {
    height: 18rem;
}

#intro {
    padding-top: 20rem;
    padding-bottom: 6.5rem;
}

@media (min-width: 480px) {
    #intro {
        padding-top: 24.875rem;
    }
}


@media (min-width: 960px) {
    #intro {
        padding-top: 14.875rem;
    }
}


#tags {
    border-radius: 1.875rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.tag {
    height: 8rem;
}

.tag>.tag-image {
    width: 8rem;
}

.tag>.tag-details {
    width: 14rem;
}

@media (min-width: 480px) {
    .tag>.tag-details {
        width: 16rem;
    }

    #tags {
        padding-top: 3.375rem;
        padding-bottom: 4.875rem;
        padding-right: 3.375rem;
        padding-left: 3.375rem;
    }

}

div>.tag:first-child {
    margin-bottom: 2.5rem;
}

@media (min-width: 960px) {

    .tag>.tag-image {
        width: auto;
    }

    div>.tag:first-child {
        margin-left: 5rem;
        margin-right: 5rem;
        margin-bottom: 0px;
    }
}


@media (min-width: 1640px) {


    #tags {
        position: absolute;
        left: calc(19.125rem/2);
        width: calc(100vw - 19.125rem);
    }

    .tag>div {
        width: 16rem;
    }
}



#tags>h2 {
    border-bottom: 4px solid #47C178;
    width: 14.25rem;
}
</style>