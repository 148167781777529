<template>
  <div class="menu-page mt-9">
    <h1>Minha conta</h1>

    <div class="mt-6">
      <v-row align="stretch" class="ma-2">
        <v-col cols="12">
          <v-skeleton-loader v-if="loading" loading transition="fade" class="mx-auto" type="sentences"></v-skeleton-loader>

          <v-card v-else class="service-card pa-1 fill-height d-flex flex-column">
            <v-row>
              <v-col cols="12" lg="10">
                <v-card-title class="dont-break">Conta do usuario</v-card-title>
                <v-card-subtitle>Mude os as informações de conta do seu
                  usuario</v-card-subtitle>
              </v-col>
              <v-col cols="12" lg="2" class="d-flex justify-lg-end align-center">
                <v-btn class="mx-4 mb-4" color="error" @click.native="logout">
                  <v-icon left class="mr-2"> mdi-exit-run</v-icon> Sair</v-btn>
              </v-col>
            </v-row>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field autocomplete="nope" dense v-model="object.email" label="E-mail" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field autocomplete="nope" dense v-model="object.name" label="Primeiro nome" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field autocomplete="nope" dense v-model="object.last_name" label="Sobrenome" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field autocomplete="nope" v-mask="'(##) #########'" dense v-model="object.phone" label="Telefone" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.native="dialogNewPassword = true">Redefinir Senha</v-btn>
              <v-btn color="primary" @click.native="createOrUpdateObject" :loading="buttonLoading">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialogNewPassword" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5 mb-2">
          <span>Nova Senha</span>

          <v-spacer></v-spacer>

          <v-btn icon @click.native="
            (dialogNewPassword = false), (objectNewPassword = {})
            ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field type="password" autocomplete="nope" dense v-model="objectNewPassword.password" label="Senha atual" outlined hide-details="auto"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field type="password" autocomplete="nope" dense v-model="objectNewPassword.newPassword" label="Nova senha" outlined hide-details="auto"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field type="password" autocomplete="nope" dense v-model="objectNewPassword.confirmNewPassword" label="Confirmar nova senha" outlined hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.native="changePassword" :loading="loading">Mudar senha</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
  name: "ContaView",

  data() {
    return {
      object: {},
      dialogNewPassword: false,
      objectNewPassword: { password: '', newPassword: '', confirmNewPassword: '' },
      buttonLoading: false,
      buttonDialogLoading: false,
      loading: true,
    };
  },

  components: {},

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      this.object = await Api.getRemoteUser();
      this.loading = false;
    },

    async createOrUpdateObject() {
      this.buttonLoading = true;
      const resp = await Api.createOrUpdateUser(this.object);

      if (resp && resp.message) {
        if (resp.error) {
          this.buttonLoading = false;
          return emitToastr("error", resp.message);
        } else {
          emitToastr("success", "Operação bem sucedida.");
        }
      }
      await this.init();
      this.buttonLoading = false;
    },

    async changePassword() {
      if (this.objectNewPassword.newPassword.length < 4) return emitToastr('error', 'A senha precisa ter pelo menos 4 caracteres.')
      if (this.objectNewPassword.newPassword != this.objectNewPassword.confirmNewPassword) return emitToastr('error', 'A senha não confere com a confirmação de senha.')
      if (!this.objectNewPassword.password) return emitToastr('error', 'É necessário digitar a senha antiga.')

      this.loading = true;
      const resp = await Api.changeUserPassword(this.objectNewPassword.password, this.objectNewPassword.newPassword);
      this.loading = false;
      if (resp && resp.error === false) {
        emitToastr('success', 'Senha alterada com sucesso!');
        this.dialogNewPassword = false;
      }

      if (resp.error && resp.message) {
        emitToastr('error', resp.message);
      }
    },

    logout() {
      Api.logout();
    },
  },
};
</script>
