import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/assets/global.css";
import "@/assets/typographics.css";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueApexCharts from 'vue-apexcharts';

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueApexCharts)
Vue.component('apexChart', VueApexCharts)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAvFanbPnZB6L3ZLAAGbzieFEbj99HHpNU",
    libraries: "places",
  },
});

import AppHeader from "@/components/app/AppHeader.vue";
import AppButton from "@/components/app/AppButton.vue";
import AppFooter from "@/components/app/AppFooter.vue";

Vue.component("AppHeader", AppHeader);
Vue.component("AppButton", AppButton);
Vue.component("AppFooter", AppFooter);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
